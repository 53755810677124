import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import googleMapsConfig from '../../Auth/GoogleMapsConfig';

export const MapContent = ({ zoom = 10, children, centerCoordinate }) => {
	const apiKey = googleMapsConfig.googleMapsKey;

	const containerStyle = {
		width: '100%',
		height: '100%',
	};

	const defaultCenter = {
		lat: -3.745,
		lng: -38.523,
	};

	const [mapInstance, setMapInstance] = useState(null);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: apiKey,
		libraries: ['drawing', 'places'], // Correctamente definido como array
	});

	if (!isLoaded) return null; // Muestra un estado vacío si no está cargado

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={centerCoordinate || defaultCenter} // Usa defaultCenter si centerCoordinate es null/undefined
			zoom={zoom}
			onLoad={(map) => setMapInstance(map)} // Asigna el mapa a mapInstance
			options={{
				draggableCursor: 'default',
				draggingCursor: 'pointer',
				streetViewControl: true,
				disableDefaultUI: true,
			}}>
			{mapInstance && children}{' '}
			{/* Renderiza hijos solo si el mapa está cargado */}
		</GoogleMap>
	);
};
