import React from 'react';
import {
	GeneralNames,
	TableNames,
	WorkAreaNames,
} from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';
import { IoMdPeople } from 'react-icons/io';
import useWorkCenter from '../../../../hooks/catalogs/WorkCenter/useWorkCenter';
import { InputSwitch } from 'primereact/inputswitch';
import workCenterObject from '../../../../Dto/Catalogs/WorkCenterObject';
import { headerClassName } from '../../../../assets/customStyles';
import { CustomPaginatorTable } from '../../../../components/Table/CustomPaginatorTable';

export const TableComponentWorkArea = ({
	filtersList,
	setFiltersList,
	renderHeader,
	filters,
	sort,
	setSort,
	editCollaboratorsAction,
	setIsEdit,
	setActiveStep,
	setOpenModal,
	formikDataObject,
	setGeolocation,
	setCoordinates,
	setAddress,
	setListOfCollaborators,
	setInitialsCollaborators,
	setIsAddressValid,
	setOpenDialog,
	setWorkCenterDelete,
	getPaginatorWorkCenter,
}) => {
	const { getWorkCenterById, putStatusWorkCenter } = useWorkCenter();
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';

	const loadWorkCenter = async (rowData) => {
		try {
			setIsEdit(true);
			let result = await getWorkCenterById(rowData.workCenterId);
			if (result.status === 200) {
				let info = result.data.data;
				formikDataObject.values.toAdd = [];
				formikDataObject.values.toDelete = [];
				formikDataObject.values.workCenterId = info.workCenterId;
				formikDataObject.values.name = info.name;
				formikDataObject.values.geoFenceSize = info.geoFenceSize;
				formikDataObject.values.isActive = info.isActive;
				formikDataObject.values.workCenterId = rowData.workCenterId;
				formikDataObject.values.zipCode = info.zipCode;
				formikDataObject.values.street = info.street;
				formikDataObject.values.geoLocation = info.geoLocation;
				formikDataObject.values.externalNumber = info.externalNumber;
				formikDataObject.values.internalNumber = info.internalNumber;
				formikDataObject.values.suburb = info.suburb;
				formikDataObject.values.federalEntity = info.federalEntity;
				formikDataObject.values.homeOffice = info.homeOffice;
				const coordinates = info.geoLocation;
				const newCoordinates = coordinates.split(',');

				const geolocation = {
					lat: Number(newCoordinates[0]),
					lng: Number(newCoordinates[1]),
				};
				setGeolocation(geolocation);
				setCoordinates(geolocation);
				let address =
					info.street !== undefined && info.street !== null
						? info.street
						: undefined;
				if (address !== undefined)
					if (info.externalNumber !== undefined && info.externalNumber !== null)
						address = `${address} ${info.externalNumber}, ${info.zipCode}`;
					else address = `${address}, ${info.zipCode}`;
				else address = info.zipCode;
				setAddress(address);
				setListOfCollaborators(info.collaborators);
				setInitialsCollaborators(info.collaborators);
				setIsAddressValid(false);
				setActiveStep(1);
				setOpenModal({ open: true, isAdd: false });
			} else {
				cleanWorkForm();
			}
		} catch (error) {
			cleanWorkForm();
		}
	};

	const cleanWorkForm = () => {
		setOpenModal({ open: false, isAdd: false });
		formikDataObject.resetForm();
		formikDataObject.setValues({ ...workCenterObject });
		setListOfCollaborators([]);
		setInitialsCollaborators([]);
		setAddress('');
		setActiveStep(0);
		setIsEdit(false);
	};

	const putChangeStatusWorkCenter = async (rowData) => {
		try {
			let result = await putStatusWorkCenter(rowData.workCenterId);
			if (result?.status === 200) {
				setFiltersList(
					[...filtersList].map(function (data) {
						if (data.workCenterId === rowData.workCenterId) {
							data = { ...data, isActive: !rowData.isActive };
						}
						return data;
					})
				);
				rowData.isActive = !rowData.isActive;
			}
		} catch (error) {}
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<div className='flex justify-center content-center items-center'>
				<div className='flex w-3 h-3  rounded-full justify-center content-center items-center   cursor-pointer ml-1 '>
					<p title='Colaboradores'>
						<IoMdPeople
							className='text-2xl text-black'
							onClick={async () => {
								await loadWorkCenter(rowData);
							}}
						/>
					</p>
				</div>
				{rowData.homeOffice === false ? (
					<div className='flex w-3 h-3  rounded-full justify-center content-center items-center   cursor-pointer ml-1 '>
						<p title='Editar'>
							<MdModeEditOutline
								className='text-2xl text-black'
								onClick={() => editCollaboratorsAction(rowData)}
							/>
						</p>
					</div>
				) : null}
				<div className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
					<p title='Eliminar'>
						<MdDelete
							className='text-2xl text-black'
							onClick={() => {
								setWorkCenterDelete(rowData.workCenterId);
								setOpenDialog(true);
							}}
						/>
					</p>
				</div>
			</div>
		);
	};
	const isActiveBodyTemplate = (rowData) => {
		return (
			<>
				<div className='flex justify-center content-center items-center w-full h-full'>
					<div title='Cambiar estatus'>
						<InputSwitch
							className={`!p-0 pl-1`}
							checked={rowData.isActive}
							onChange={(e) => putChangeStatusWorkCenter(rowData)}
						/>
					</div>
				</div>
			</>
		);
	};

	return (
		<CustomPaginatorTable
			value={filtersList}
			sortField='name'
			header={renderHeader()}
			//loading={loading}
			getFunctionPaginator={getPaginatorWorkCenter}
			filters={filters}
			responsiveLayout='scroll'
			globalFilterFields={['name', 'fullAddress', 'isActive']}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={WorkAreaNames.CurrentPageReportTemplate}
			resizableColumns
			columnResizeMode='fit'
			style={{ height: '100%', width: '100%' }}
			setSort={setSort}
			sort={sort}>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 8, flexBasis: '100px' }}
				field='name'
				header={TableNames.TableName}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 7, flexBasis: '100px' }}
				field='fullAddress'
				header={TableNames.TableAddress}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '30px' }}
				field='isActive'
				className='!p-1'
				header={TableNames.TableState}
				sortable
				body={isActiveBodyTemplate}
				bodyClassName={bodyClassName}
			/>

			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '50px' }}
				header={TableNames.TableActions} //Para eliminar encuesta
				body={actionBodyTemplate}
				bodyClassName={bodyClassName}
			/>
		</CustomPaginatorTable>
	);
};
