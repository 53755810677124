import { DataTable } from 'primereact/datatable';

import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';

import { classNames } from 'primereact/utils';
import { useState } from 'react';

export const CustomPaginatorTable = ({
	children,
	style,
	header,
	sortField,
	sort,
	setSort,
	doubleClick,
	tableClassName,
	getFunctionPaginator,
	height = 'h-9',
	rows = undefined,
	id = undefined,
	...props
}) => {
	const [first1, setFirst1] = useState(0);
	const [rows1, setRows1] = useState(25);
	const [currentPage, setCurrentPage] = useState(1);
	const [showMenu, setShowMenu] = useState(false);
	const h = showMenu ? ' h-68 md:h-60 ' : ' h-5 ';

	const onCustomPage1 = (event) => {
		const _currentPage = event.page + 1;

		setFirst1(event.first);
		setRows1(event.rows);
		setCurrentPage(_currentPage);
		getFunctionPaginator(currentPage, event.rows);
	};

	function onCustomRows(event) {
		getFunctionPaginator(currentPage, event.rows);
	}

	const template1 = {
		layout:
			'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
		PrevPageLink: (options) => {
			return (
				<button
					type='button'
					className={options.className}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span className='p-3'>{'Anterior'}</span>
					<Ripple />
				</button>
			);
		},
		NextPageLink: (options) => {
			return (
				<button
					type='button'
					className={options.className}
					onClick={options.onClick}
					disabled={options.disabled}>
					<span className='p-3'>{'Siguiente'}</span>
					<Ripple />
				</button>
			);
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true,
				});

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				);
			}

			return (
				<button
					type='button'
					className={options.className}
					onClick={options.onClick}>
					{options.page + 1}
					<Ripple />
				</button>
			);
		},
		RowsPerPageDropdown: (options) => {
			const dropdownOptions = [
				{ label: 25, value: 25 },
				{ label: 50, value: 50 },
				{ label: 75, value: 75 },
				{ label: 100, value: 100 },
			];

			return (
				<Dropdown
					value={options.value}
					options={dropdownOptions}
					onChange={(e) => {
						options.onChange(e);
						onCustomRows(e);
					}}
				/>
			);
		},
	};
	return (
		<div className='flex w-full h-full flex-col'>
			{header !== undefined ? (
				<>
					<div
						className={
							'hidden lg:flex xl:flex 2xl:flex w-full  py-1 pl-1  ' + height
						}>
						{header}
					</div>
					<div
						className={
							'flex lg:hidden xl:hidden 2xl:hidden w-full h-full  py-1 pl-1 border' +
							h
						}>
						{showMenu === false ? (
							<div
								onClick={() => setShowMenu(!showMenu)}
								className='flex  w-full  justify-end content-start items-center '>
								<p className='!text-primary-ShadowPCP004 mr-2 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
									<span className={'icon-menu'}></span>
								</p>
							</div>
						) : (
							<div className='flex lg:hidden xl:hidden 2xl:hidden w-full h-full py-1 pl-1 border flex-row'>
								<div className='flex h-full flex-grow'>{header}</div>
								<div
									onClick={() => setShowMenu(!showMenu)}
									className='flex   justify-end content-start items-center'>
									<p className='!text-primary-ShadowPCP004 mr-2 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
										<span className={'icon-menu'}></span>
									</p>
								</div>
							</div>
						)}
					</div>
				</>
			) : null}
			<div className='flex w-full h-full pl-1 pr-1'>
				<div className='flex w-full h-full relative  border shadow-2xl'>
					<DataTable
						first={first1}
						rows={rows1}
						id={id !== undefined ? id : 'testId'}
						removableSort
						resizableColumns
						sortField={sort.field}
						sortOrder={sort.order}
						scrollable
						paginator
						paginatorTemplate={template1}
						onPage={onCustomPage1}
						columnResizeMode='fit'
						scrollHeight='flex'
						scrollDirection='both'
						stripedRows
						onRowDoubleClick={(e) => {
							if (doubleClick) {
								doubleClick(e.data);
							}
						}}
						onSort={(e) => {
							setSort(e.multiSortMeta);
						}}
						sortMode='multiple'
						multiSortMeta={sort}
						currentPageReportTemplate='Total de registros {totalRecords}'
						tableClassName={'!absolute !top-0 !left-0 !right-0 !bottom-0 '}
						className='flex w-full h-full relative !font-karla !text-[14px]'
						rowClassName='hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004 '
						size='small'
						{...props}>
						{children}
					</DataTable>
				</div>
			</div>
		</div>
	);
};
