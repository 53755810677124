import React, { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { CustomTable } from '../../../../components/Table/CustomTable';
import { GeneralNames, ScheduleNames } from '../../../../namesConstants/names';
import { LocationBody } from './LocationBody';
import { BodyWorkShiftTemplate } from './BodyWorkShiftTemplate';
import { ActionBodyTemplate } from './ActionBodyTemplate';
import {
	bodyClassName,
	headerClassName,
	tableColumnsData,
} from '../Const/Constants';

export const TableOperation = ({
	list,
	filters,
	setFilter,
	setOpenWorkCenterLocationDialog,
	setIdPersoniaCollaborator,
	setIsHomeOffice,
	selectedCollaborators,
	setSelectedCollaborators,
	setTitle,
	setOpenWorkShifDialog,
	setIsMassive,
	setWorkShiftSelected,
	getWorkCenterLocationCollaboratorsOnOperation,
	loading,
	updateCollaboratorOpenWorkArea,
	collaborator, 
	setCollaborator,
	sort,
	setSort
}) => {
	const [valueInputFilters, setValueInputFilters] = useState({
		formattedCode: '',
		fullName: '',
		periodTypeName: '',
		jobPositionaName: '',
		jobDepartmentName: '',
		shift: '',
		leaderName: '',
	});

	

	function openDialogSingle(row) {
		setCollaborator(row)
		setIsMassive(false);
		setOpenWorkShifDialog(true);
		setIdPersoniaCollaborator(row.id);
		setWorkShiftSelected(row.shiftDetailId);
	}

	function handleChange(e) {
		const { value, name } = e.target;
		const _FinalValues = { ...valueInputFilters, [name]: value };
		setValueInputFilters(_FinalValues);
		const _FinalFilters = {
			...filters,
			[name]: { value: value, matchMode: FilterMatchMode.CONTAINS },
		};
		setFilter(_FinalFilters);
	}

	function clearFilter(name) {
		const _FinalValues = { ...valueInputFilters, [name]: '' };
		setValueInputFilters(_FinalValues);
		const _FinalFilters = {
			...filters,
			[name]: { value: null, matchMode: FilterMatchMode.CONTAINS },
		};
		setFilter(_FinalFilters);
	}

	const rowFilterMultiSelectTemplate = (name, value) => {
		return (
			<InputText
				className={`w-full !rounded-md !text-font-size-base`}
				id={name}
				value={value}
				placeholder={'Buscar'}
				name={name}
				onChange={(e) => {
					handleChange(e);
				}}
			/>
		);
	};

	const renderSwitchBody = (field, rowData) => {
		switch (field) {
			case 'actions':
				return (
					<ActionBodyTemplate
						row={rowData}
						getWorkCenterLocationCollaboratorsOnOperation={
							getWorkCenterLocationCollaboratorsOnOperation
						}
						openDialogSingle={openDialogSingle}
						setIdPersoniaCollaborator={setIdPersoniaCollaborator}
						setIsHomeOffice={setIsHomeOffice}
						setOpenWorkCenterLocationDialog={setOpenWorkCenterLocationDialog}
						setTitle={setTitle}
					/>
				);
			case 'openWorkArea':
				return <LocationBody row={rowData} updateCollaboratorOpenWorkArea={updateCollaboratorOpenWorkArea} />;
			default:
				return (
					<BodyWorkShiftTemplate
						row={rowData}
						openDialogSingle={openDialogSingle}
					/>
				);
		}
	};

	return (
		<CustomTable
			loading={loading}
			//id={'DataTableOperationCollaborators'}
			//rows={100}
			value={list}
			sortField='name'
			filters={filters}
			responsiveLayout='scroll'
			globalFilterFields={[
				'formattedCode',
				'fullName',
				'periodTypeName',
				'jobPositionaName',
				'jobDepartmentName',
				'shift',
				'leaderName',
			]}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={ScheduleNames.CurrentPageReportTemplate}
			resizableColumns
			//filterDisplay='menu'
			columnResizeMode='fit'
			//	style={{ height: '100%', width: '100%' }}
			setSort={setSort}
			sort={sort}
			selection={selectedCollaborators}
			selectionMode={'checkbox'}
			onSelectionChange={(e) => setSelectedCollaborators(e.value)}>
			<Column
				selectionMode={'multiple'}
				headerClassName={headerClassName}
				bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
				style={{ minWidth: '10px' }}
			/>

			{tableColumnsData.map((column, index) => {
				let key = index.toString();
				return (
					<Column
						key={key}
						filterHeaderClassName={
							'!border-r-2 !border-b-0 !border-secondary-PCS004'
						}
						headerClassName={headerClassName}
						//	className='!flex-grow !flex-basis-200'
						style={{ flexGrow: 2, flexBasis: column.height }}
						field={column.field}
						header={column.label}
						sortable={column.sortable}
						filter={column.filter}
						showFilterMenu={column.showFilterMenu}
						showFilterOperator={column.showFilterMatchModes}
						showFilterMatchModes={column.showFilterMatchModes}
						filterField={column.field}
						filterPlaceholder={column.filterPlaceHolder}
						onFilterClear={() => {
							clearFilter(column.field);
						}}
						bodyClassName={
							column?.bodyClassName ? column?.bodyClassName : bodyClassName
						}
						filterElement={rowFilterMultiSelectTemplate(
							column.field,
							valueInputFilters[column.field]
						)}
						body={(rowData) => {
							return column.body
								? renderSwitchBody(column.field, rowData)
								: rowData[column.field];
						}}
					/>
				);
			})}
		</CustomTable>
	);
};
