import { actionTypesOperations } from '../../constans/operation';

export const getAttendanceDetail_InitialState = {
	data: null,
	error: null,
	loading: false,
	isLoadData: false,
};

export const getAttendanceDetailReducer = (state, actions) => {
	switch (actions.type) {
		case actionTypesOperations.GET_ATTENDANCE_DETAIL.BASE:
			return {
				...state,
				loading: true,
			};
		case actionTypesOperations.GET_ATTENDANCE_DETAIL.ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				data: null,
				isLoadData: false,
			};
		case actionTypesOperations.GET_ATTENDANCE_DETAIL.SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
				isLoadData: true,
			};

		default:
			return state;
	}
};
