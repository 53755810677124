import React, { useContext, useState, useEffect } from 'react';
import { MainContainer } from './../../../components/MainContainer/index';
import { TableComponentWorkArea } from './components/TableComponent';
import { initialStateTable } from '../../Invitations/const';
import {
	GeneralNames,
	WorkAreaCollaboratorNames,
	WorkAreaNames,
	workCenterScreenNames,
} from '../../../namesConstants/names';
import { ToolbarTwoColumns } from '../../../components/Table/ToolbarTwoColumns';
import { StoreContext } from '../../../business/Provider';
import useReload from '../../../hooks/useReload';
import { WorkCenterDialog } from './components/WorkCenterDialog';
import { useFormik } from 'formik';
import workCenterObject from '../../../Dto/Catalogs/WorkCenterObject';
import workCenterSchema from '../../../Validations/Catalogs/WorkCenterSchema';
import { Dialog } from 'primereact/dialog';
import { CollaboratorGridWorkCenter } from './components/CollaboratorGridWorkCenter';
import { BaseButton } from '../../../components/Buttons/BaseButton';
import { CustomAlertDialog } from '../../../components/Dialogs/CustomAlertDialog';
import { ToolbarSearch } from '../../../components/ToolbarSearch';
import useWorkCenter from '../../../hooks/catalogs/WorkCenter/useWorkCenter';

export const WorkArea = () => {
	const [openDialog, setOpenDialog] = useState(false);
	const [workCenterDelete, setWorkCenterDelete] = useState(undefined);
	const [isAddressValid, setIsAddressValid] = useState(false);
	const { getWorkCenterState, getCollaboratorsWorkCenterState } =
		useContext(StoreContext);
	const {
		getWorkCenters,
		postWorkCenter,
		patchWorkCenter,
		getWorkCenterById,
		deleteWorkCenter,
		getCollaboratorsByWorkCenter,
	} = useWorkCenter();
	useReload([getWorkCenters]);
	const [filtersList, setFiltersList] = useState([]);
	const [filters, setFilters] = useState(initialStateTable);
	const [sort, setSort] = useState([{ field: '', order: -1 }]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [openModal, setOpenModal] = useState({ open: false, isAdd: false });
	const [openAddCollaboratorsDialog, setOpenAddCollaboratorsDialog] =
		useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [listOfCollaborators, setListOfCollaborators] = useState([]);
	const [address, setAddress] = useState('');
	const [collaborators, setCollaborators] = useState([]);
	const [geolocation, setGeolocation] = useState(null);
	const [circles, setCircles] = useState([]);
	const [initialsCollaborators, setInitialsCollaborators] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [coordinates, setCoordinates] = useState(geolocation); //[{lat: 0, lng: 0}

	useEffect(() => {
		if (
			getWorkCenterState.loading === false &&
			getWorkCenterState.workCenters !== undefined
		) {
			setFiltersList(getWorkCenterState?.workCenters?.results);
		}
	}, [getWorkCenterState]);

	useEffect(() => {
		if (
			getCollaboratorsWorkCenterState.loading === false &&
			getCollaboratorsWorkCenterState.collaborators !== undefined
		) {
			setCollaborators(getCollaboratorsWorkCenterState?.collaborators?.data);
		}
	}, [getCollaboratorsWorkCenterState]);

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	};

	useEffect(() => {
		clearTextLabel();
	}, []);

	const clearTextLabel = () => {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setGlobalFilterValue(newValue);
		setFilters(_filters);
	};

	const addOrUpdateWorkCenter = async () => {
		//loadingObj.setLoading(true);
		try {
			let idCollaborators = [];
			if (listOfCollaborators.length > 0) {
				listOfCollaborators.forEach((item) => {
					idCollaborators.push(item.employeeId);
				});
			}
			//formikDataObject.setFieldValue('collaborators', [idCollaborators]);
			formikDataObject.values.toAdd = idCollaborators;
			openModal.isAdd
				? await postWorkCenter(formikDataObject.values)
				: await patchWorkCenter(formikDataObject.values);
			//await getWorkCenters();
			setOpenModal({ open: false });
			formikDataObject.resetForm();
			setListOfCollaborators([]);
			setAddress('');
			setActiveStep(0);
			setGeolocation(null);
			setCoordinates(null);
		} catch (error) {}
	};

	const formikDataObject = useFormik({
		initialValues: { ...workCenterObject },
		validationSchema: workCenterSchema,
		onSubmit: addOrUpdateWorkCenter,
	});

	async function getPaginatorWorkCenter(page, pageSize) {
		return getWorkCenters(page, pageSize);
	}

	const editCollaboratorsAction = async (rowData) => {
		setIsEdit(true);
		formikDataObject.setValues({ ...workCenterObject });

		let result = await getWorkCenterById(rowData.workCenterId);
		if (result.status === 200) {
			let info = result.data.data;

			formikDataObject.setValues({
				...formikDataObject.values,
				workCenterId: info.workCenterId,
				name: info.name,
				geoFenceSize: info.geoFenceSize,
				zipCode: info.zipCode,
				street: info.street,
				geoLocation: info.geoLocation,
				externalNumber: info.externalNumber,
				internalNumber: info.internalNumber,
				suburb: info.suburb,
				federalEntity: info.federalEntity,
				homeOffice: info.homeOffice,
				isActive: info.isActive,
				municipality: info.municipality,
			});
			const coordinates = info.geoLocation;
			const newCoordinates = coordinates.split(',');

			const geolocation = {
				lat: Number(newCoordinates[0]),
				lng: Number(newCoordinates[1]),
			};
			setGeolocation(geolocation);
			setCoordinates(geolocation);
			setCircles([geolocation]);

			setAddress(info.fullAddress);
			setListOfCollaborators(info.collaborators);
			setInitialsCollaborators(info.collaborators);
			setOpenModal({ isAdd: false, open: true });
			setIsAddressValid(false);
		} else {
			setIsAddressValid(true);
		}
	};

	const handleEditWorkCenter = async () => {
		try {
			const usuariosEliminados = initialsCollaborators.filter(
				(usuario) => !listOfCollaborators.includes(usuario)
			);
			const usuariosAgregados = listOfCollaborators.filter(
				(usuario) => !initialsCollaborators.includes(usuario)
			);

			let idCollaboratorsDelete = [];
			if (usuariosEliminados.length > 0) {
				usuariosEliminados.forEach((item) => {
					idCollaboratorsDelete.push(item.employeeId);
				});
			}

			let idCollaboratorsAdd = [];
			if (usuariosAgregados.length > 0) {
				usuariosAgregados.forEach((item) => {
					idCollaboratorsAdd.push(item.employeeId);
				});
			}

			formikDataObject.values.toDelete = idCollaboratorsDelete;
			formikDataObject.values.toAdd = idCollaboratorsAdd;
			await patchWorkCenter(formikDataObject.values);
			setFiltersList(_filterData);

			initStatesModal();
		} catch (error) {
			initStatesModal();
		}
	};

	const initStatesModal = () => {
		setOpenModal({ open: false });
		formikDataObject.resetForm();
		setListOfCollaborators([]);
		setAddress('');
		setActiveStep(0);
		setGeolocation(null);
		setCoordinates(null);
		setIsEdit(false);
		formikDataObject.setValues({ ...workCenterObject });
	};

	function addFunctionIcon() {
		setOpenModal({ open: true, isAdd: true });
		setIsEdit(false);
	}

	const iconsData = [
		{ icon: 'add', title: 'Agregar', onClick: addFunctionIcon },
	];

	const renderHeader = () => {
		return (
			<ToolbarSearch
				onChange={onGlobalFilterChange}
				placeholder={GeneralNames.GeneralNamesSearchGeneral}
				value={globalFilterValue}
				clearTextLabel={clearTextLabel}
				iconsTable={iconsData}
			/>
		);
	};
	const renderTable = () => {
		return (
			<TableComponentWorkArea
				setWorkCenterDelete={setWorkCenterDelete}
				setAddress={setAddress}
				formik={formikDataObject}
				getWorkCenter={getWorkCenterById}
				filtersList={filtersList}
				renderHeader={renderHeader}
				filters={filters}
				setFiltersList={setFiltersList}
				sort={sort}
				setSort={setSort}
				setOpenModal={setOpenModal}
				setCollaborators={setListOfCollaborators}
				setIsAddressValid={setIsAddressValid}
				editCollaboratorsAction={editCollaboratorsAction}
				setIsEdit={setIsEdit}
				setActiveStep={setActiveStep}
				setCircles={setCircles}
				setGeolocation={setGeolocation}
				setCoordinates={setCoordinates}
				setListOfCollaborators={setListOfCollaborators}
				setInitialsCollaborators={setInitialsCollaborators}
				initialsCollaborators={initialsCollaborators}
				formikDataObject={formikDataObject}
				setOpenDialog={setOpenDialog}
				getPaginatorWorkCenter={getPaginatorWorkCenter}
			/>
		);
	};

	const closeAction = () => {
		setOpenModal({ ...openModal, open: false });
		formikDataObject.resetForm();
		setListOfCollaborators([]);
		setActiveStep(0);
		setAddress('');
		setGeolocation(null);
		setCoordinates(null);
		formikDataObject.values.geoFenceSize = 500;
		formikDataObject.setValues({ ...workCenterObject });
	};

	const renderAlert = () => {
		return (
			<CustomAlertDialog
				title={workCenterScreenNames.CustomAlertDialogTittleReject}
				setOpen={setOpenDialog}
				open={openDialog}
				message={workCenterScreenNames.CustomAlertDialogSuccessMessageDelete}
				cancelLabel={GeneralNames.GeneralCancel}
				agreeLabel={GeneralNames.GeneralAccept}
				agreeIcon='pi pi-check'
				onConfirmFunction={() => deleteWorkCenterAction(workCenterDelete)}
				onCancelFunction={() => setOpenDialog(false)}
				style={{ width: '30vw' }}
			/>
		);
	};

	const deleteWorkCenterAction = async (id) => {
		let result = await deleteWorkCenter(id);
		if (result?.status === 200) {
			setFiltersList(filtersList.filter((e) => e.workCenterId !== id));
		}
	};

	const validateForm = async () => {
		//Get All Errors
		const validationErrors = await formikDataObject.validateForm();
		//delete key from object if not exist in validationFields

		if (Object.keys(validationErrors).length > 0) {
			Object.keys(validationErrors).forEach((element) =>
				formikDataObject.setFieldTouched(element, true)
			);
			return false;
		} else {
			isEdit === true
				? await handleEditWorkCenter()
				: await addOrUpdateWorkCenter();
			return true;
		}
	};

	const onGlobalFilterChangeTableCollaboratorEdit = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filtersTableCollaboratorEdit };
		_filters.global.value = newValue;
		setFiltersTableCollaboratorEdit(_filters);
		setGlobalFilterValueTableCollaboratorEdit(newValue);
	};

	const clearTextLabelTableCollaboratorEdit = () => {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filtersTableCollaboratorEdit };
		_filters.global.value = newValue;
		setGlobalFilterValueTableCollaboratorEdit(newValue);
		setFiltersTableCollaboratorEdit(_filters);
	};

	const [filtersTableCollaboratorEdit, setFiltersTableCollaboratorEdit] =
		useState(initialStateTable);
	const [
		globalFilterValueTableCollaboratorEdit,
		setGlobalFilterValueTableCollaboratorEdit,
	] = useState('');
	function getCollaboratorsByWorkCenterByPaginator(page, pageSize) {
		return getCollaboratorsByWorkCenter(page, pageSize);
	}

	const renderHeaderGrid = () => {
		return (
			<div className='w-full flex flex-row !h-[60px]'>
				<ToolbarTwoColumns
					hidden={true}
					onChange={onGlobalFilterChangeTableCollaboratorEdit}
					placeholder={WorkAreaCollaboratorNames.FindCollaborator}
					value={globalFilterValueTableCollaboratorEdit}
					clearTextLabel={clearTextLabelTableCollaboratorEdit}
					type='Edit'
					onClickAction={() => {
						if (!formikDataObject.values.homeOffice) {
							setOpenAddCollaboratorsDialog(true);
						}
					}}
				/>
			</div>
		);
	};
	const renderCollaboratorList = () => {
		return (
			<div className='w-full h-full flex-col flex'>
				{renderHeaderGrid()}
				<CollaboratorGridWorkCenter
					listOfCollaborators={collaborators}
					toSend={listOfCollaborators}
					setToSend={setListOfCollaborators}
					name={'collaboratorgrid'}
					isAdd //={openModal.isAdd}
					text={'Por favor agregue colaboradores'}
					filters={filtersTableCollaboratorEdit}
				/>
				<div className='flex justify-end py-1 mr-2  gap-x-1'>
					<BaseButton
						type='submit'
						onClick={() => setOpenAddCollaboratorsDialog(false)}
						label={'Aceptar'}
					/>
				</div>
			</div>
		);
	};

	return (
		<MainContainer cancelTittle nameScreen={WorkAreaNames.WorkAreaScreenTitle}>
			{renderTable()}
			{renderAlert()}
			<WorkCenterDialog
				title={
					openModal.isAdd
						? 'Agregar centro de trabajo'
						: 'Editar centro de trabajo'
				}
				open={openModal.open}
				onClose={() => closeAction()}
				activeStep={activeStep}
				setActiveStep={setActiveStep}
				formik={formikDataObject}
				onSubmit={() => validateForm()}
				setIsEdit={setIsEdit}
				openModalCollaborators={setOpenAddCollaboratorsDialog}
				address={address}
				setAddress={setAddress}
				listOfCollaborators={listOfCollaborators}
				isAddressValid={isAddressValid}
				setIsAddressValid={setIsAddressValid}
				geolocation={geolocation}
				setGeolocation={setGeolocation}
				circles={circles}
				setCircles={setCircles}
				coordinates={coordinates}
				setCoordinates={setCoordinates}
				isEdit={isEdit}
				getCollaboratorsByWorkCenterByPaginator={
					getCollaboratorsByWorkCenterByPaginator
				}
				getCollaboratorsByWorkCenter={getCollaboratorsByWorkCenter}
			/>
			{/* This is a component from the PrimeReact library that displays a dialog box with a header,
			content, and buttons. The `header` prop is a JSX element that displays the title of the dialog
			box. The `visible` prop determines whether the dialog box is visible or not. The `modal` prop
			makes the dialog box modal, meaning that it blocks interactions with the rest of the application
			until it is closed. The `style` prop sets the width and height of the dialog box. The `onHide`
			prop is a function that is called when the dialog box is closed. */}
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
						{openModal.isAdd ? 'Agregar colaboradores' : 'Editar colaboradores'}
					</p>
				}
				visible={openAddCollaboratorsDialog}
				modal
				style={{ width: '75vw', height: '80vh' }}
				onHide={() => setOpenAddCollaboratorsDialog(false)}>
				<div className='flex w-full h-full flex-col'>
					{renderCollaboratorList()}
				</div>
			</Dialog>
		</MainContainer>
	);
};
