import React, { useState } from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import { GeneralNames, WorkAreaNames } from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import useWorkCenter from '../../../../hooks/catalogs/WorkCenter/useWorkCenter';
import useReload from '../../../../hooks/useReload';
import { headerClassName } from '../../../../assets/customStyles';
import { CustomPaginatorTable } from '../../../../components/Table/CustomPaginatorTable';
export const CollaboratorGridWorkCenter = ({
	listOfCollaborators,
	toSend,
	setToSend,
	getCollaboratorsByWorkCenterByPaginator,
	isAdd = false,
	filters,
}) => {
	const { getCollaboratorsByWorkCenter } = useWorkCenter();
	useReload([getCollaboratorsByWorkCenter]);

	const [sort, setSort] = useState([{ field: 'fullName', order: 1 }]);

	// const headerClassName =
	// 	'hover:!text-success  !bg-secondary-PCS004 !text-secondary-PCS001 !font-semibold !border-r-2 !border-secondary-PCS003';
	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black';
	return (
		<CustomPaginatorTable
			value={listOfCollaborators}
			getFunctionPaginator={getCollaboratorsByWorkCenterByPaginator}
			sortField='name'
			filters={filters}
			responsiveLayout='scroll'
			globalFilterFields={[
				'formattedCode',
				'fullName',
				'zipCode',
				'municipality',
				'workShift',
			]}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={WorkAreaNames.CurrentPageReportTemplate}
			resizableColumns
			columnResizeMode='fit'
			style={{ height: '100%', width: '100%' }}
			setSort={setSort}
			selection={toSend}
			selectionMode='checkbox'
			onSelectionChange={(e) => {
				if (isAdd) {
					setToSend(e.value);
				}
			}}
			sort={sort}>
			{isAdd ? (
				<Column
					headerClassName={headerClassName}
					bodyClassName='!text-center !text-secondary-PCS004 !border-r-2 !border-b-0 !border-secondary-PCS004'
					selectionMode='multiple'
					style={{ minWidth: '10px' }}
				/>
			) : null}
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '60px' }}
				field='formattedCode'
				header={'Código'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='fullName'
				header={'Nombre'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='zipCode'
				header={'Código postal'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='municipality'
				header={'Entidad Federativa'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='workShift'
				header={'Turno'}
				sortable
				bodyClassName={bodyClassName}
			/>
		</CustomPaginatorTable>
	);
};
