import { LOADING_OFF, LOADING_ON } from '../../../business/constants';
import { Toast } from '../../../components/toast';
import { GeneralNames, TableNames } from '../../../namesConstants/names';
import { appName, formatDateForExcelHeader, sortArray } from '../../../utils/excelUtils';
import {
	downloadFileBase64,
	getFileNameWithDateTime,
	sortByDataTable,
} from '../../../utils/utils';

function dataToExcel(dataOrg, companyInfo, sort) {
    const data = getDataExport(sortArray(dataOrg, sort[0]));
	const toExcel = {
		filename: 'Organigrama',
		templateFileName: 'simple_template.xlsx',
		replace: [
			{
				type: 0,
				tag: '#simple.name',
				prop: 'name',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#simple.company',
				prop: 'company',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#printDate',
				prop: 'printDate',
				default: new Date().toLocaleDateString(),
				format: ['numberFormat', 'dd mmmm yyyy'],
			},
			{
				type: 1,
				tag: '#simple.appName',
				prop: 'appName',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#simple.name',
				prop: 'name',
				default: 'Simple',
			},
			{
				type: 2,
				tag: '#simple.cols',
				prop: 'cols',
			},
			{
				type: 3,
				tag: '#simple.rows',
				prop: 'rows',
				colprop: 'cols',
			},
		],
		data: {
			company: companyInfo?.companies?.name ? companyInfo.companies.name : '',
			autoFilter: true,
			gridLinesVisible: true,
			printDate: formatDateForExcelHeader(new Date()),
			appName: appName,
			name: 'Organigrama',
			cols: [],
			rows: data,
		},
	};
	headers.forEach((header, index) => {
		const col = {
			title: headersTranslations[index],
			prop: header,
			width: wchCols[index].wch,
		};
		toExcel.data.cols.push(col);
	});
	return toExcel;
}
function removeLeadingZeros(num) {
	if (!isNaN(num)) {
		return num;
	}
	return Number(num).toString();
}
function getDataExport(dataOrg) {
	// Verificamos si sort tiene algún valor
	return dataOrg.map((item) => {
		return {
			code: item.code,
			fullName: item.fullName,
			position: item.position,
			department: item.department,
			actualLeaderFullName: item.actualLeaderFullName,
			actualLeaderCompanyName: item.actualLeaderCompanyName,
		};
	});
}

export async function toExcelExportOrgChart(
	data,
	dispatchLoading,
	companyInfo,
	sort
) {
	if (data.length > 0) {
		dispatchLoading({ type: LOADING_ON });
		const toExcel = dataToExcel(data, companyInfo, sort);

		const filName = getFileNameWithDateTime('Organigrama');
		await downloadFileBase64(toExcel, 'xlsx', filName)
			.then(() => {
				dispatchLoading({ type: LOADING_OFF });
			})
			.catch((error) => {
				dispatchLoading({ type: LOADING_OFF });
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	} else {
		Toast(
			'warning',
			GeneralNames.EmptyDataMessageExport,
			GeneralNames.EmptyDataMessageBodyExport,
			'success'
		);
	}
}

export const headers = [
	'code',
	'fullName',
	'position',
	'department',
	'actualLeaderFullName',
	'actualLeaderCompanyName',
];

export const headersTranslations = [
	TableNames.TableCode,
	TableNames.TableName,
	TableNames.TablePosition,
	TableNames.TableDepartment,
	TableNames.TableActualLeader,
	TableNames.TableCompanyName,
];

const wchCols = [
	{ wch: headersTranslations[0].length + 5 },
	{ wch: 30 },
	{ wch: 30 },
	{ wch: 30 },
	{ wch: 30 },
	{ wch: 30 },
];
