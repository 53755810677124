import React from 'react';

import {
	GeneralNames,
	InvitationsScreenNames,
} from '../../../namesConstants/names';
import { regexName } from '../../../utils/utils';

import { IconsPanelControl } from '../../../components/IconsPanelControl';
import CustomDatePicker from '../../../components/Calendar/CustomDatePicker';
import CustomDropdown from '../../../components/Input/Dropdown';
import { SearchComponent } from '../../../components/SearchComponent';
export const HeaderComponent = ({ service }) => {
	const placeholderNameAdditional = 'Invitación';

	const renderStartDateComponent = () => (
		<CustomDatePicker
			//	today={null}
			value={service.headerFilters.startDate}
			setValue={service.onHandleHeaderFilters}
			label={
				placeholderNameAdditional !== ''
					? GeneralNames.GeneralStartDate + ' ' + placeholderNameAdditional
					: GeneralNames.GeneralStartDate
			}
			name={'startDate'}
			showIcon
			labelClassName='text-small-font-size font-semibold font-input-font-family'
			//maxDate={endDate}
			//size={12}
		/>
	);

	const renderEndDate = () => (
		<CustomDatePicker
			value={service.headerFilters.endDate}
			setValue={service.onHandleHeaderFilters}
			label={
				placeholderNameAdditional !== ''
					? GeneralNames.GeneralEndDate + ' ' + placeholderNameAdditional
					: GeneralNames.GeneralEndDate
			}
			name={'endDate'}
			showIcon
			labelClassName='text-small-font-size font-semibold font-input-font-family'
			minDateField={service.headerFilters.startDate}
			//size={12}
		/>
	);
	const renderStartAndEndDate = () => {
		return (
			<div className='flex h-full w-full gap-x-1'>
				<div className='flex h-full w-1/2'>{renderStartDateComponent()}</div>
				<div className='flex h-full w-1/2 '>{renderEndDate()}</div>
			</div>
		);
	};

	const renderStatusEmployee = () => (
		<CustomDropdown
			name='selectFilterEmployee'
			value={service.headerFilters.selectFilterEmployee}
			handler={service.onHandleHeaderFilters}
			options={service.employeeOptionsFilter}
			optionLabel='label'
			optionValue='value'
			className='!border-r !border-border flex-grow'
		/>
	);

	const renderStatus = () => (
		<CustomDropdown
			name='statusValueSelected'
			value={service.headerFilters.statusValueSelected}
			handler={service.onHandleHeaderFilters}
			options={service.optionsOfFilters}
			optionLabel='label'
			optionValue='value'
			className='!border-r !border-border flex-grow'
		/>
	);

	const renderFilter = () => {
		return (
			<div className='flex w-full h-full gap-x-1'>
				<div className='flex w-1/3 h-full'>{renderStatusEmployee()}</div>
				<div className='flex w-1/3 h-full'>{renderStatus()}</div>
				<div className='flex h-full w-2/3'>{renderStartAndEndDate()}</div>
			</div>
		);
	};

	const renderToolBarWeb = () => {
		return (
			<div className='hidden lg:flex xl:flex 2xl:flex w-full h-full'>
				<div className='flex w-1/5 h-full p-1'>{renderSearchComponent()}</div>
				<div className='flex w-3/5 h-full p-1 '>{renderFilter()}</div>
				<div className='flex w-1/5 h-full p-1'>
					<IconsPanelControl iconsData={service.iconsData} />
				</div>
			</div>
		);
	};

	const renderSearchComponent = () => (
		<SearchComponent
			placeholder={InvitationsScreenNames.PlaceHolderDropdown}
			value={service.inputTextSearchValue}
			regexName={regexName}
			onChange={service.handleInputTextSearch}
			clearTextLabel={service.clearTextLabel}
		/>
	);

	const renderToolbarMobile = () => {
		return (
			<div className='flex lg:hidden xl:hidden 2xl:hidden flex-wrap'>
				<div className='flex w-full md:w-1/2 h-6 p-1'>
					{renderSearchComponent()}
				</div>
				<div className='flex w-full md:w-1/2  h-6 gap-x-1'>
					<div className='flex w-full h-full p-1  '>{renderStatus()}</div>
				</div>

				<div className='flex w-full md:w-1/2  h-6 gap-x-1'>
					<div className='flex h-full w-full p-1'>
						{renderStartDateComponent()}
					</div>
				</div>

				<div className='flex w-full md:w-1/2  h-6 gap-x-1 '>
					<div className='flex h-full w-full p-1  '>{renderEndDate()}</div>
				</div>

				<div className='flex w-full md:w-1/2 h-6 p-1'>
					<IconsPanelControl iconsData={service.iconsData} />
				</div>
			</div>
		);
	};
	return (
		<>
			{renderToolBarWeb()}
			{renderToolbarMobile()}
		</>
	);
};
