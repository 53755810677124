import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { formatDateToYYYYMMDD, generateUrlBase } from '../../utils/utils';
import {
	GET_QUERIES,
	GET_QUERIES_ERROR,
	GET_QUERIES_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
} from '../../business/constants';
import { Toast } from '../../components/toast';
import { generateDynamicColumns } from '../../screens/Queries/components/utils';

export const useQueries = () => {
	const context = useContext(StoreContext);

	const {
		dispatchGetQueries,
		dispatchLoading,
		getQueriesState,
		getAttendanceDetailReportContestState,
	} = context;
	const {
		getAttendanceDetailReport_DP,
		getAttendanceDetailReportError_DP,
		getAttendanceDetailReportSuccess_DP,
	} = getAttendanceDetailReportContestState;
	const { genericApiCall } = useApi();

	const getQueries = async (data, typeQuery, totalizer) => {
		const { startDate, endDate, typeIncidents } = data;

		dispatchGetQueries({ type: GET_QUERIES });
		dispatchLoading({ type: LOADING_ON });
		const dataToSend = JSON.stringify(data);
		const dataToBase64 = btoa(dataToSend);
		const baseUrl =
			typeQuery.code === 'C'
				? generateUrlBase('reports/attendances?filters=' + dataToBase64)
				: generateUrlBase('reports/incidences?filters=' + dataToBase64);
		data.startDate = formatDateToYYYYMMDD(data.startDate);
		data.endDate = formatDateToYYYYMMDD(data.endDate);

		try {
			const result = await genericApiCall(
				baseUrl,
				'POST',
				dataToBase64,
				{},
				{}
			);
			const payload = result.data.results;

			if (result.status === 200) {
				const columns = generateDynamicColumns(
					false,
					startDate,
					endDate,
					typeQuery,
					totalizer,
					typeIncidents
				);

				dispatchGetQueries({
					type: GET_QUERIES_SUCCESS,
					payload: {
						tableData: payload,
						columns: columns,
						nameScreen: typeQuery.name,
					},
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetQueries({
					type: GET_QUERIES_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchGetQueries({ type: GET_QUERIES_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getReportQuery = async (data) => {
		console.log('data xxxxxxxxxxxxx', data);
		getAttendanceDetailReport_DP();
		dispatchLoading({ type: LOADING_ON });
		const dataToSend = JSON.stringify(data);
		const dataToBase64 = btoa(dataToSend);
		const urlBase = generateUrlBase('reports/attendance-detail');

		return await genericApiCall(urlBase, 'POST', dataToBase64, {}, {})
			.then((response) => {
				if (response.status === 200) {
					getAttendanceDetailReportSuccess_DP(response.data.results);
				}
			})
			.catch((error) => {
				console.log('Error', error);
				Toast('warning', error.message, '', error);
				getAttendanceDetailReportError_DP();
			})
			.finally(() => {
				dispatchLoading({ type: LOADING_OFF });
			});
	};

	function clearQueries() {
		return dispatchGetQueries({ type: 'Clear' });
	}

	useEffect(() => {
		const { queries } = getQueriesState;

		if (queries === null) {
			clearQueries();
		}
	}, []);

	return { getQueries, clearQueries, getReportQuery };
};
