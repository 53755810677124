import React, { useState } from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import {
	GeneralNames,
	ScheduleNames,
	TableNames,
} from '../../../../namesConstants/names';
import { Column } from 'primereact/column';
import { MdModeEditOutline } from 'react-icons/md';
import { headerClassName } from '../../../../assets/customStyles';
import { numbersToStringWithCommas } from '../utils';
import { CustomPaginatorTable } from '../../../../components/Table/CustomPaginatorTable';
export const TableSchedule = ({
	list,
	filters,
	getSchedulesByPaginator,
	handleOnEditSchedule,
}) => {
	const [sort, setSort] = useState([{ field: 'name', order: 1 }]);

	const bodyClassName =
		'!border-r-2 !border-b-0 !border-secondary-PCS004 !text-black !justify-start';

	const actionBodyTemplate = (row) => {
		return (
			<div className='flex justify-center content-center items-center'>
				<div className='flex w-3 h-3  rounded-full justify-center content-center items-center  cursor-pointer ml-1'>
					<MdModeEditOutline
						className='text-2xl text-[#669ED4]'
						onClick={() => handleOnEditSchedule(row)}
						title='Editar'
					/>
				</div>
			</div>
		);
	};

	const enumDays = {
		2: 'Lun',
		3: 'Mar',
		4: 'Mié',
		5: 'jue',
		6: 'Vie',
		7: 'Sáb',
		1: 'Dom', //
	};

	const bodyHolidaysTd = (row) => {
		const restDaysSplit = row.restDays.split(',');
		restDaysSplit.sort((a, b) => [Number(a)] - Number(b));
		const newRestDays = restDaysSplit.map((item) => enumDays[item]);

		return (
			<div className='flex w-full h-full flex-row'>
				<span className='ml-1'>{numbersToStringWithCommas(newRestDays)}</span>
			</div>
		);
	};
	return (
		<CustomPaginatorTable
			value={list}
			sortField='name'
			filters={filters}
			responsiveLayout='scroll'
			globalFilterFields={['code', 'description', 'schedule', 'workShiftName']}
			emptyMessage={GeneralNames.GeneralEmptyMessage}
			currentPageReportTemplate={ScheduleNames.CurrentPageReportTemplate}
			resizableColumns
			columnResizeMode='fit'
			style={{ height: '100%', width: '100%' }}
			setSort={setSort}
			getFunctionPaginator={getSchedulesByPaginator}
			sort={sort}>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='code'
				header={'Clave'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='description'
				header={'Nombre'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '100px' }}
				field='schedule'
				header={'Horario (24 Horas)'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '100px' }}
				field='workShiftName'
				header={'Turno Nómina'}
				sortable
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 4, flexBasis: '200px' }}
				field='workShiftName'
				header={'Días de descanso'}
				sortable
				body={bodyHolidaysTd}
				bodyClassName={bodyClassName}
			/>
			<Column
				headerClassName={headerClassName}
				style={{ flexGrow: 1, flexBasis: '50px' }}
				header={TableNames.TableActions}
				body={actionBodyTemplate}
				bodyClassName={bodyClassName}
			/>
		</CustomPaginatorTable>
	);
};
