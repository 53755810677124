export function formatDateForExcelHeader(date) {
  return new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
}
export const appName = "CONTPAQi Colabora";

export function sortArray(data, config) {
    const { field, order } = config;
    const orderFactor = order === 1 ? 1 : -1; // Define el orden (ascendente o descendente)

    return [...data].sort((a, b) => {
        const valA = a[field];
        const valB = b[field];

        // Manejo de valores nulos o indefinidos
        if (valA == null || valA === undefined || valA === "") return 1; // `null` y `undefined` van al final
        if (valB == null || valB === undefined || valB === "") return -1;

        // Comparación para fechas
        if (valA instanceof Date && valB instanceof Date) {
            return (valA - valB) * orderFactor;
        }

        // Comparación para cadenas y números
        const blocksA = divideString(String(valA).toLowerCase());
        const blocksB = divideString(String(valB).toLowerCase());

        return compareBlocks(blocksA, blocksB, orderFactor);
    });
}

function divideString(string) {
    // Divide en bloques de letras, números y otros caracteres
    return string.match(/[A-Za-z]+|\d+|[^A-Za-z\d]/g).map(block =>
        isNaN(block) ? block : parseInt(block, 10)
    );
}

function compareBlocks(blocksA, blocksB, orderFactor) {
    const length = Math.min(blocksA.length, blocksB.length);

    for (let i = 0; i < length; i++) {
        const isANumeric = typeof blocksA[i] === "number";
        const isBNumeric = typeof blocksB[i] === "number";

        // Prioriza números sobre letras
        if (isANumeric && !isBNumeric) return -1 * orderFactor;
        if (!isANumeric && isBNumeric) return 1 * orderFactor;

        // Si ambos son del mismo tipo, compara directamente
        if (blocksA[i] < blocksB[i]) return -1 * orderFactor;
        if (blocksA[i] > blocksB[i]) return 1 * orderFactor;
    }

    // Si todos los bloques son iguales, compara por longitud
    return (blocksA.length - blocksB.length) * orderFactor;
}


export function formatDateWithoutTime(dateString) {
  const date = new Date(dateString);
  // Convierte a ISO (ej: "2024-12-01T00:00:00.000Z") y después extrae la parte de la fecha antes de la "T"
  return date.toISOString().split("T")[0];
}