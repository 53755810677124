import { createActionTypes } from '../../../utils/utils';

const actionBases = [
	'POST_WORK_SHIFT_SCHEDULE',
	'DELETE_LEADER_ORGANIZATION_CHART',
	'GET_FILTER_OPERATION',
	'GET_ATTENDANCE_DETAIL',
	// Puedes agregar más aquí
];

export const actionTypesOperations = actionBases.reduce((acc, base) => {
	acc[base] = createActionTypes(base);
	return acc;
}, {});
