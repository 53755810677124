import { useContext } from 'react';
import { StoreContext } from '../../business/Provider';
import { LOADING_OFF, LOADING_ON } from '../../business/constants';

import {
	downloadFileExcel,
	generateUrlBase,
	getFileNameWithDateTime,
} from '../../utils/utils';
import { Toast } from '../../components/toast';
import axios from 'axios';
import { getLocalAccessToken } from '../../Auth/auth/token';

export const useExport = () => {
	const context = useContext(StoreContext);
	const { dispatchLoading } = context;
	const accessToken = getLocalAccessToken();
	const ses = sessionStorage.getItem('sessionState');
	const d = new Date();
	let diff = d.getTimezoneOffset();
	let timeOffset = diff / 60;

	const combinedHeaders = {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${accessToken}`,
		sessionState: ses,
		timeOffset: timeOffset,
	};

	const handleExport = async (url, data = {}, params = {}, method = 'POST') => {
		dispatchLoading({ type: LOADING_ON });

		try {
			const response = await axios({
				method,
				url,
				data,
				params,
				headers: combinedHeaders,
				responseType: 'blob',
			});
			dispatchLoading({ type: LOADING_OFF });
			return response;
		} catch (error) {
			dispatchLoading({ type: LOADING_OFF });
			if (error.response && error.response.status === 401) {
				Toast(
					'warning',
					'Sesión expirada',
					'La página se actualizará para volver a ver información'
				);
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			} else {
				Toast('warning', error.message, '', error);
			}
			throw error;
		}
	};

	const exportExcelAction = async (data) => {
		const baseUrl = generateUrlBase('operations/collaborators/export');
		return await handleExport(baseUrl, data);
	};

	const exportPayRollData = async (data) => {
		const urlBase = generateUrlBase(
			'incidences/report?periodId=' + data.periodId
		);
		return await handleExport(urlBase, {}, {}, 'GET');
	};

	const exportAttendanceRegister = async (searchValue = '', body = {}) => {
		const params = {
			search: searchValue,
			sort: '',
		};
		const dataToSend = JSON.stringify(body);
		const dataToBase64 = btoa(dataToSend);
		const urlBase = generateUrlBase(`reports/export-attendance-detail`);
		return await handleExport(urlBase, dataToBase64, params, 'POST').then(
			async (response) => {
				if (response.status === 200) {
					dispatchLoading({ type: LOADING_ON });
					const fillName = getFileNameWithDateTime('Registro de Asistencia');
					await downloadFileExcel(response, fillName)
						.then(() => {
							dispatchLoading({ type: LOADING_OFF });
						})
						.catch(() => {
							dispatchLoading({ type: LOADING_OFF });
						});
				}
			}
		);
	};

	return { exportExcelAction, exportPayRollData, exportAttendanceRegister };
};
