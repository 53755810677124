// GoogleMapsConfig.js
class GoogleMapsConfig {
	constructor() {
		this._googleMapsKey = null; // Clave privada
	}

	// Getter para obtener la clave
	get googleMapsKey() {
		if (!this._googleMapsKey) {
			console.warn('Google Maps Key no está configurada.');
		}
		return this._googleMapsKey;
	}

	// Setter para establecer la clave
	set googleMapsKey(key) {
		if (typeof key !== 'string' || key.trim() === '') {
			throw new Error('La clave debe ser una cadena no vacía.');
		}
		this._googleMapsKey = key;
	}
}

// Exportar una instancia única de la clase
const googleMapsConfig = new GoogleMapsConfig();
export default googleMapsConfig;
