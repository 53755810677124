import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavigationContext } from '../../context/navigationContext';

import './index.css';
import { Button } from 'primereact/button';
import { BreadCrumbItem } from '../BreadCrumbItem';
import { useQueries } from '../../hooks/queries/useQueries';
import { QueriesServiceManager } from '../../context/wrapperContext/queries/FilterContext';

export const BreadCrumb = () => {
	const { clearQueries } = useQueries();
	const { clearFilters } = useContext(QueriesServiceManager);
	/**
	 * If the item has a saveBeforeClose property and it's true and the data is valid, then show the
	 * validation alert and set the urlStage to the url. Otherwise, remove the tap
	 */
	const close = (url, item) => {
		if (url === '/queries') {
			clearQueries();
			clearFilters();
		}
		if (
			item.saveBeforeClose !== undefined &&
			item.saveBeforeClose === true &&
			navigation.validData === true
		) {
			navigation.setShowValidationAlertSave(true);
			navigation.setUrlStage(url);
		} else {
			navigation.removeTap(url);
		}
	};
	// usePrevious
	const [left, setLeft] = useState(0);
	const navigation = useContext(NavigationContext);
	const content = [];
	navigation?.taps.map((item, key) => {
		content.push(
			<BreadCrumbItem
				close={close}
				redirect={navigation.selectTap}
				item={item}
				key={key}
			/>
		);
	});
	// div ref
	const scroll = useRef(null);
	// function to add scroll
	const setScroll = (scrollOffset, type) => {
		scroll.current.scrollLeft += scrollOffset;
		if (type === 'left') {
			setLeft(scroll.current.scrollLeft);
		} else {
			if (left != scroll.current.scrollLeft) {
				setLeft(scroll.current.scrollLeft);
			}
		}
	};

	const ArrowLeft = () => {
		const hidden = Number(left) === 0;
		return hidden ? (
			<></>
		) : (
			<Button
				className='!bg-transparent !text-black !border-border '
				hidden={hidden}
				onClick={() => setScroll(-40, 'left')}
				icon='pi pi-arrow-left'
				iconPos='left'
			/>
		);
	};
	const ArrowRight = () => {
		const scrollWidth = scroll.current?.scrollWidth;
		const windowWidth = window.innerWidth - 90;
		const hidden = Number(left) + windowWidth >= scrollWidth;
		return !hidden ? (
			<Button
				className='!bg-transparent !text-black !border-border '
				onClick={() => setScroll(40, 'right')}
				icon='pi pi-arrow-right'
				iconPos='right'
			/>
		) : (
			<></>
		);
	};

	return (
		<>
			{/* {ArrowLeft()} */}
			<div className='relative flex-grow w-[44px]'>
				<ul
					ref={scroll}
					className='absolute left-0 right-0 noScrollbar overflow-x-auto h-full flex'>
					{content}
				</ul>
			</div>
			{/* {ArrowRight()} */}
		</>
	);
};
// create button with icon for left and right
