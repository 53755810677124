import { useContext } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { generateUrlBase } from '../../utils/utils';
import {
	GET_INCIDENCES,
	GET_INCIDENCES_BY_PROCESS_ID,
	GET_INCIDENCES_ERROR,
	GET_INCIDENCES_ERROR_BY_PROCESS_ID,
	GET_INCIDENCES_SUCCESS,
	GET_INCIDENCES_SUCCESS_BY_PROCESS_ID,
	LOADING_OFF,
	LOADING_ON,
	POST_INCIDENCES,
	POST_INCIDENCES_ERROR,
	POST_INCIDENCES_SUCCESS,
} from '../../business/constants';
import { Toast } from '../../components/toast';

export const useIncidences = (filtersData, setStatusBar) => {
	const context = useContext(StoreContext);
	const {
		dispatchGetIncidences,
		dispatchLoading,
		dispatchPostIncidences,
		dispatchGetIncidencesByProcessId,
	} = context;
	const { genericApiCall } = useApi();

	const getIncidences = async (params) => {
		const baseUrl = generateUrlBase('operations/incidences');

		dispatchGetIncidences({ type: GET_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });

		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, params, {});
			const payload = result.data;

			if (result.status === 200) {
				dispatchGetIncidences({
					type: GET_INCIDENCES_SUCCESS,
					payload: payload,
				});
			}
			return result;
		} catch (error) {
			dispatchGetIncidences({ type: GET_INCIDENCES_ERROR });
			Toast('warning', error.message, '', error);
		} finally {
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const getIncidencesByIdProgress = async (idProcesses) => {
		let completedProcesses = 0; // Contador de procesos completados

		const promises = idProcesses.map(async (idProcess) => {
			const baseUrl = generateUrlBase(
				'operations/incidences/process/' + idProcess
			);

			dispatchGetIncidencesByProcessId({
				type: GET_INCIDENCES_BY_PROCESS_ID,
			});

			try {
				const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
				const payload = result.data;

				if (result.status === 200) {
					dispatchGetIncidencesByProcessId({
						type: GET_INCIDENCES_SUCCESS_BY_PROCESS_ID,
						payload: payload,
					});

					if (payload.data.step === 4) {
						completedProcesses++;
						const progress = Math.round(
							(completedProcesses / idProcesses.length) * 100
						);
						setStatusBar(progress); // Actualizamos la barra de progreso
					}
					return payload;
				} else {
					Toast('warning', 'Error en la obtención de datos', '', result);
					return null; // Manejo de error individual
				}
			} catch (error) {
				dispatchGetIncidencesByProcessId({
					type: GET_INCIDENCES_ERROR_BY_PROCESS_ID,
				});
				Toast('warning', error.message, '', error);
				return null; // Manejo de error individual
			}
		});

		// Esperar a que todas las promesas se resuelvan
		const results = await Promise.all(promises);

		// Verificar si todos los steps están en 4
		const allStepsCompleted = results.every(
			(res) => res && res.data.step === 4
		);

		if (allStepsCompleted) {
			dispatchLoading({ type: LOADING_OFF });
			Toast('success', 'Todas las incidencias aplicadas con éxito', '', '');
			return await getIncidences(filtersData);
		} else {
			// Reintentar si no todos los steps están en 4
			setTimeout(() => getIncidencesByIdProgress(idProcesses), 5000);
		}
	};

	const postIncidences = async (params, filters) => {
		const baseUrl = generateUrlBase('operations/incidences/process');

		dispatchPostIncidences({ type: POST_INCIDENCES });
		dispatchLoading({ type: LOADING_ON });

		try {
			const result = await genericApiCall(baseUrl, 'POST', params, {}, {});
			const payload = result.data;

			if (result.status === 200) {
				dispatchPostIncidences({
					type: POST_INCIDENCES_SUCCESS,
					payload: payload,
				});

				// Llamar a getIncidencesByIdProgress con el arreglo de IDs
				return await getIncidencesByIdProgress(payload.data);
			} else {
				Toast('warning', 'Error al enviar incidencias', '', result);
			}
		} catch (error) {
			dispatchPostIncidences({ type: POST_INCIDENCES_ERROR });
			Toast('warning', error.message, '', error);
		} finally {
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	return { getIncidences, postIncidences };
};
