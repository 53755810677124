import { useContext } from 'react';
import {
	downloadFileBase64,
	getFileNameWithDateTime,
	StatusArrayValuesTable,
	validateEndDate,
	validateStartDate,
	validateStartDateAndEndDate,
} from '../../utils/utils';

import { LOADING_OFF, LOADING_ON } from '../../business/constants';
import { Toast } from '../../components/toast';
import { GeneralNames } from '../../namesConstants/names';
import {
	EmployeeStatusEnum,
	headers,
	headersTranslations,
	wchCols,
} from './const';
import { appName, formatDateForExcelHeader, sortArray } from '../../utils/excelUtils';

export function filterDataInvitations(
	data,
	selectFilter,
	selectFilterEmployee,
	startDate,
	endDate
) {
	let invitationsFiltered = [];
	let newItem =
		selectFilter !== 'all'
			? data.filter((item) => {
					if (selectFilter === 'unavailable') {
						return item.email === null;
					}
					if (selectFilter === 0) {
						return item.email !== null && item.invitationStatus === 0;
					} else {
						return item.invitationStatus === selectFilter;
					}
			  })
			: data;
	// Aplicar filtro adicional para statusEmployee
	newItem =
		newItem !== undefined
			? newItem.filter((item) => {
					const statusEmployee = parseInt(selectFilterEmployee);
					// Validar si statusEmployee es 0 o 1
					if (statusEmployee === 0 || statusEmployee === 1) {
						return item.statusEmployee === statusEmployee;
					}
					// Si statusEmployee no es 0 ni 1, no aplicar filtro y devolver el item, ya que seleccionó todos
					return true;
			  })
			: newItem;
	if (startDate !== null && endDate === null) {
		const arrayListByStartDate = validateStartDate(startDate, newItem);
		//setFiltersList(ArrayListByStartDate);
		invitationsFiltered = arrayListByStartDate;
	} else if (startDate === null && endDate !== null) {
		const arrayListByEndDate = validateEndDate(endDate, newItem);
		//setFiltersList(ArrayListByEndDate);
		invitationsFiltered = arrayListByEndDate;
	} else if (startDate !== null && endDate !== null) {
		const arrayListByDate = validateStartDateAndEndDate(
			startDate,
			endDate,
			newItem
		);
		//setFiltersList(ArrayListByDate);
		invitationsFiltered = arrayListByDate;
	} else {
		//setFiltersList(newItem);
		invitationsFiltered = newItem;
	}
	return invitationsFiltered;
}

export async function toExcelExport(filtersList, dispatchLoading, companyInfo, sort) {
	if (filtersList.length > 0) {
		dispatchLoading({ type: LOADING_ON });
		const toExcel = dataToExcel(filtersList, companyInfo, sort);
		const filName = getFileNameWithDateTime('Invitaciones');
		await downloadFileBase64(toExcel, 'xlsx', filName)
			.then(() => {
				dispatchLoading({ type: LOADING_OFF });
			})
			.catch((error) => {
				dispatchLoading({ type: LOADING_OFF });
			});
	} else {
		Toast(
			'warning',
			GeneralNames.EmptyDataMessageExport,
			GeneralNames.EmptyDataMessageBodyExport,
			'success'
		);
	}
}

function formatDate(date) {
	return date.toISOString().split('T')[0]; //? new Intl.DateTimeFormat('es-ES').format(new Date(date)) : '';
}
function dataToExcel(filtersList, companyInfo, sort) {
	const data = getDataExport(sortArray(filtersList, sort[0]));
	// modificar data en los campos requestDate y statusChangeDate para formato de fecha dd/mm/yyyy
	const dataExcel = data.map(
		({
			additionalInfo,
			email,
			formattedCode,
			fullName,
			invitationResponseDate,
			invitationSendingDate,
			invitationStatus,
			statusEmployee,
		}) => ({
			additionalInfo,
			email,
			formattedCode,
			fullName,
			invitationResponseDate:
				invitationResponseDate === 'No disponible'
					? ''
					: formatDate(invitationResponseDate),
			invitationSendingDate:
				invitationSendingDate === 'No disponible'
					? ''
					: formatDate(invitationSendingDate),
			invitationStatus,
			statusEmployee,
		})
	);
	const toExcel = {
		filename: 'Invitaciones',
		templateFileName: 'simple_template.xlsx',
		replace: [
			{
				type: 0,
				tag: '#simple.name',
				prop: 'name',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#simple.company',
				prop: 'company',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#printDate',
				prop: 'printDate',
				default: new Date().toLocaleDateString(),
				format: ['numberFormat', 'dd mmmm yyyy'],
			},
			{
				type: 1,
				tag: '#simple.appName',
				prop: 'appName',
				default: 'Simple',
			},
			{
				type: 1,
				tag: '#simple.name',
				prop: 'name',
				default: 'Simple',
			},
			{
				type: 2,
				tag: '#simple.cols',
				prop: 'cols',
			},
			{
				type: 3,
				tag: '#simple.rows',
				prop: 'rows',
				colprop: 'cols',
			},
		],
		data: {
			company: companyInfo?.companies?.name ? companyInfo.companies.name : '',
			autoFilter: true,
			gridLinesVisible: true,
			printDate: formatDateForExcelHeader(new Date()),
			appName: appName,
			name: 'Invitaciones',
			cols: [],
			rows: dataExcel,
		},
	};
	// cols = [{ title: "", prop:"", width: ""}]
	// title es el nombre de la columna y prop es el nombre de la propiedad del objeto
	// width es el ancho de la columna
	headers.forEach((header, index) => {
		const col = {
			title: headersTranslations[index],
			prop: header,
			width: wchCols[index].wch,
		};
		toExcel.data.cols.push(col);
	});
	return toExcel;
}

function getDataExport(listInvitation) {
	let arrayExport = [];
	listInvitation.forEach((invitation) => {
		let filterStatus = StatusArrayValuesTable.filter(
			(item) => item.value === invitation.invitationStatus
		)[0].label;
		let invitationStatus =
			invitation.email === null || invitation.email === ''
				? 'No disponible'
				: filterStatus;
		let data = {
			formattedCode: invitation.code,
			fullName: invitation.fullName,
			email: invitation.email,
			statusEmployee: EmployeeStatusEnum[invitation.statusEmployee],
			invitationSendingDate:
				invitation.invitationSendingDate ?? 'No disponible',
			invitationResponseDate:
				invitation.invitationResponseDate ?? 'No disponible',
			invitationStatus: invitationStatus,
			additionalInfo: invitation.additionalInfo,
		};
		arrayExport.push(data);
	});
	return arrayExport;
}
