import { useContext, useState } from 'react';
import useApi from '../api';

import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';

export const useIntegrations = () => {
	const { genericApiCall } = useApi();
	const context = useContext(StoreContext);
	const { companyState, loadingContextState } = context;
	const { companies } = companyState;

	const { loadingOnDp, loadingOffDp } = loadingContextState;

	// Base URL and messages constants
	const BASE_URL =
		window.REACT_INTEGRATIONS_API_URL +
		'/v1/integration/process-direct-message';
	const INTEGRATION_MESSAGES = {
		50: 'Importando datos para catálogos, espere...',
		203: 'Importando datos para empleados, espere...',
		206: 'Importando datos para organigrama, espere...',
		208: 'Importando datos para registro de asistencia, espere...',
	};

	// Function to handle API calls for integrations
	const postIntegrations = async (_type = 50) => {
		const message = INTEGRATION_MESSAGES[_type];
		if (message) loadingOnDp(message);

		const isPayrollCall = JSON.parse(sessionStorage.getItem('isPayrollCall'));
		let data = {
			contentType: '',
			messageConfig: {
				sincronizationId: 'd336e881-78d8-477a-b12a-cda03d8b6587',
				type: _type,
				instanceId: companies?.instanceId,
				companyId: companies?.companyId,
			},
			body: {
				origin: isPayrollCall ? 30 : 10,
			},
		};

		if (_type === 208) {
			data.body = {
				...data.body,
				action: 3,
				entity: _type,
				data: {
					instanceId: companies?.instanceId,
					companyId: companies?.companyId,
				},
			};
		}

		return await genericApiCall(BASE_URL, 'POST', data, {}, {})
			.then((result) => {
				console.log('result de postIntegrations', result);
				return result;
			})
			.catch((error) => {
				Toast('warning', error.message || 'Error en la integración', '', error);
				throw error; // Re-throw for centralized handling
			})
			.finally(() => {
				loadingOffDp();
			});
	};

	// useEffect to handle integrations

	return {
		postIntegrations,
	};
};
