import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import React from 'react';

export const DialogProgressBar = ({ showDialog, percentageBar }) => {
	return (
		<Dialog visible={showDialog} style={{ width: '50vw' }} closable={false}>
			<div className='flex h-16 w-full justify-center items-center content-center flex-col'>
				<div className='flex w-full h-1/2 justify-center content-center items-center font-bold'>
					<p>{'Procesando...'}</p>
				</div>
				<ProgressBar
					value={percentageBar}
					className='!flex !w-full !h-1/2 !text-black !items-center !content-center'
				/>
			</div>
		</Dialog>
	);
};
