import React, { useContext, useEffect, useState } from 'react';
import { MainContainer } from '../../../components/MainContainer';
import { GeneralNames, SidebarNames } from '../../../namesConstants/names';
import { initialStateTable } from '../../Invitations/const';
import { TableTypesIncidences } from '../Events/TableComponent/TableTypesIncidences';
import { ToolbarSearch } from '../../../components/ToolbarSearch';
import { useTypeIncidences } from '../../../hooks/incidences/useTypeIncidences';
import useReload from './../../../hooks/useReload';
import { StoreContext } from '../../../business/Provider';
import { Dialog } from 'primereact/dialog';

import { useFormik } from 'formik';
import { incidencesObject } from './../../../Dto/Catalogs/incidences/incidencesObject';
import { incidencesSchema } from '../../../Validations/Catalogs/incidences/incidencesSchema';

import { FormComponent } from './components/Form/FormComponent';

import { considerationTypeData, typesIncidencesData } from './utils';

export const Events = () => {
	const context = useContext(StoreContext);
	const { getTypeIncidencesState, getAccumulatedState, loadingState } = context;
	const [filters, setFilters] = useState(initialStateTable);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [typesIncidences, setTypesIncidences] = useState([]);
	const [showDialog, setShowDialog] = useState(false);
	const [accumulatedData, setAccumulatedData] = useState([]);
	const [isEdit, setIsEdit] = useState(false);

	const {
		getTypeIncidences,
		editIncidences,
		createIncidences,
		updateTypeIncident,
	} = useTypeIncidences();

	useReload([getTypeIncidences]);

	useEffect(() => {
		clearTextLabel();
	}, []);

	useEffect(() => {
		if (getTypeIncidencesState.incidences !== null) {
			const { results } = getTypeIncidencesState.incidences;
			setTypesIncidences(results);
		}
	}, [getTypeIncidencesState]);

	useEffect(() => {
		if (getAccumulatedState.accumulated !== null) {
			const { results } = getAccumulatedState?.accumulated;
			let dataResults =
				results === undefined && results === null ? [] : results;
			setAccumulatedData(dataResults);
		}
	}, [getAccumulatedState]);

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	}

	function clearTextLabel() {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setGlobalFilterValue(newValue);
		setFilters(_filters);
	}

	function addTypeIncidence() {
		setShowDialog(true);
		const typesOfIncidenteObject = typesIncidencesData.find((o) => o.id === 2);
		const considerationTypeObj = considerationTypeData.find((o) => o.id === 1);
		formikDataObject.setValues({
			...formikDataObject.values,

			incidenceType: typesOfIncidenteObject,
			considerationType: considerationTypeObj,
		});
	}

	async function onSubmitForm() {
		const {
			id,
			code,
			name,
			incidenceType,
			considerationType,
			getsSalary,
			decreasesSeventhDay,
			accumulatedTypes,
			leaderUse,
		} = formikDataObject.values;

		const stringsArray = accumulatedTypes.map((object) => object.id);
		const dataToSend = {
			leaderUse: leaderUse,
			code: code,
			name: name,
			incidenceType: incidenceType.id,
			considerationType: considerationType.id,
			getsSalary: getsSalary,
			decreasesSeventhDay: decreasesSeventhDay,
			accummulatedTypes: stringsArray,
		};

		const result = isEdit
			? await updateTypeIncident({ id: id, ...dataToSend })
			: await createIncidences(dataToSend);

		if (result.status === 200) {
			closeDialog();
		}
	}

	async function editTypeIncidenceAction(objectSend) {
		return await editIncidences(objectSend);
	}

	const iconsData = [
		{ icon: 'add', title: 'Agregar', onClick: addTypeIncidence },
	];
	const formikIncidencesConfig = {
		initialValues: { ...incidencesObject },
		validationSchema: incidencesSchema,
		onSubmit: onSubmitForm,
	};

	const formikDataObject = useFormik(formikIncidencesConfig);

	function closeDialog() {
		setShowDialog(false);
		formikDataObject.resetForm({
			values: { ...incidencesObject },
			errors: {},
			touched: {},
			isSubmitting: false,
		});
		setIsEdit(false);
	}

	function getTypeIncidencesByPaginator(page, pageSize) {
		return getTypeIncidences(page, pageSize);
	}

	const renderDialog = () => {
		return (
			<Dialog
				key={showDialog ? 'open' : 'closed'}
				header={isEdit ? 'Editar incidencia' : 'Crear tipo de incidencia'}
				visible={showDialog}
				className='relative'
				style={{ width: '60vw' }}
				onHide={() => closeDialog()}>
				<FormComponent
					accumulatedData={accumulatedData}
					formikDataObject={formikDataObject}
					closeDialog={closeDialog}
					isEdit={isEdit}
					loadingState={loadingState}
				/>
			</Dialog>
		);
	};

	function handleOnEditData(row) {
		setIsEdit(true);
		setShowDialog(true);
		const typesOfIncidenteObject = typesIncidencesData.find(
			(o) => o.id === row.typeOfIncident
		);
		const considerationTypeObj = considerationTypeData.find(
			(o) => o.id === row.typeItConsiders
		);
		const accumulatedTypesArr = accumulatedData.filter((item) =>
			row?.accummulatedTypes.includes(item.id)
		);
		formikDataObject.setValues({
			...row,
			getsSalary: row.salaryRight,
			incidenceType: typesOfIncidenteObject,
			considerationType:
				considerationTypeObj !== undefined
					? considerationTypeObj
					: {
							id: 1,
							name: 'Ninguno',
					  },
			accumulatedTypes: accumulatedTypesArr,
			leaderUse: row.leaderUse ?? false,
		});
	}

	const renderHeader = () => {
		return (
			<ToolbarSearch
				onChange={onGlobalFilterChange}
				placeholder={GeneralNames.GeneralNamesSearchGeneral}
				value={globalFilterValue}
				clearTextLabel={clearTextLabel}
				iconsTable={iconsData}
			/>
		);
	};

	const renderBodyMain = () => {
		return (
			<div className='w-full h-full flex flex-col'>
				<TableTypesIncidences
					list={typesIncidences}
					filters={filters}
					header={renderHeader}
					editTypeIncidenceAction={editTypeIncidenceAction}
					handleOnEditData={handleOnEditData}
					getTypeIncidencesByPaginator={getTypeIncidencesByPaginator}
				/>
			</div>
		);
	};
	return (
		<MainContainer cancelTittle nameScreen={SidebarNames.SidebarEvents}>
			{renderDialog()}
			{renderBodyMain()}
		</MainContainer>
	);
};
