import { useReducer } from 'react';
import {
	getAttendanceDetail_InitialState,
	getAttendanceDetailReducer,
} from '../../../../business/reducers/reports/getAttendanceDetailRed';
import { actionTypesOperations } from '../../../../business/constans/operation';

export const useAttendanceDetailReport = () => {
	const { GET_ATTENDANCE_DETAIL } = actionTypesOperations;
	const [stateGetAttendanceDetail, dispatch] = useReducer(
		getAttendanceDetailReducer,
		getAttendanceDetail_InitialState
	);

	const getAttendanceDetailReport_DP = () => {
		return dispatch({ type: GET_ATTENDANCE_DETAIL.BASE });
	};

	const getAttendanceDetailReportSuccess_DP = (data) => {
		return dispatch({ type: GET_ATTENDANCE_DETAIL.SUCCESS, payload: data });
	};
	const getAttendanceDetailReportError_DP = (data) => {
		return dispatch({ type: GET_ATTENDANCE_DETAIL.ERROR, payload: data });
	};

	return {
		stateGetAttendanceDetail,
		getAttendanceDetailReport_DP,
		getAttendanceDetailReportError_DP,
		getAttendanceDetailReportSuccess_DP,
	};
};
