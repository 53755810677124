import moment from 'moment';
import React, { useState, createContext, useContext, useEffect } from 'react';
import { StoreContext } from '../../../business/Provider';
import { Toast } from '../../../components/toast';
import {
	downloadFileBase64,
	generateArrayToIds,
	generateArrayToIdsInt,
} from '../../../utils/utils';
import { useQueries } from '../../../hooks/queries/useQueries';
import {
	addMissingDates,
	dataToExportChecks,
	dataToExportIncidences,
	generateDynamicColumns,
	getDateTime,
} from '../../../screens/Queries/components/utils';
import { FilterMatchMode } from 'primereact/api';
import { useReportCatalog } from '../../../hooks/reports/useReportCatalogs';
import useReload from './../../../hooks/useReload';
import { LOADING_OFF, LOADING_ON } from '../../../business/constants';
import { GeneralNames } from '../../../namesConstants/names';
import { useExport } from '../../../hooks/export/useExport';

export const QueriesServiceManager = createContext();
const today = new Date();
today.setDate(today.getDate() - 30);
const initialValuesFilter = {
	typeQuery: {
		name: 'Incidencias',
		code: 'I',
	},
	startDate: today,
	endDate: new Date(),
	typePeriods: [],
	workCenters: [],
	shifts: [],
	departments: [],
	positions: [],
	collaborators: [],
	typeIncidents: [],
	inactivesToo: false,
};
const typeQuery = [
	{ name: 'Incidencias', code: 'I' },
	{ name: 'Checadas', code: 'C' },
	{ name: 'Registro de asistencia', code: 'R' },
];
export const FilterProvider = ({ children }) => {
	const { getCatalogs } = useReportCatalog();
	const { getQueries, getReportQuery } = useQueries();
	const { exportAttendanceRegister } = useExport();
	const {
		getQueriesState,
		getCatalogReportState,
		dispatchLoading,
		loadingState,
		companyState,
		getAttendanceDetailReportContestState,
	} = useContext(StoreContext);

	const { stateGetAttendanceDetail } = getAttendanceDetailReportContestState;
	const [tableDataQuery, setTableDataQuery] = useState(null);
	const [expandableRowTableQuery, setExpandableRowTableQuery] = useState(null);

	const [sort, setSort] = useState([{ field: 'Code', order: -1 }]);
	const [sortAttendance, setSortAttendance] = useState([
		{ field: 'Code', order: -1 },
	]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [nameQuery, setNameQuery] = useState('');

	useReload([getCatalogs, getReloadData]);

	const [forceUpdateKey, setForceUpdateKey] = useState(0);
	const [forceUpdateKeyTwo, setForceUpdateKeyTow] = useState(0);
	const [dynamicColumns, setDynamicColumns] = useState({ open: [], close: [] }); //valores que vas a utilizar para la exportacion
	const [filterTypeQuerySelect, setFilterTypeQuerySelect] = useState({
		name: 'Incidencias',
		code: 'I',
	});
	const [updateTable, setUpdateTable] = useState(false);
	const [showColumnsDate, setShowColumnsDate] = useState(false);

	const [catalogData, setCatalogData] = useState({
		periodType: [],
		employee: [],
		position: [],
		workCenter: [],
		shift: [],
		department: [],
		incidentType: [],
	});

	const [filterData, setFilterData] = useState(initialValuesFilter);
	useEffect(() => {
		if (getCatalogReportState.catalogs !== null) {
			const { data } = getCatalogReportState.catalogs;

			setCatalogData(data);
		}
	}, [getCatalogReportState]);

	useEffect(() => {
		const typeIncidences =
			filterData.typeIncidents.length > 0
				? generateFilteredData(
						catalogData.incidentType,
						filterData.typeIncidents
				  )
				: null;

		const columns = generateDynamicColumns(
			showColumnsDate,
			filterData.startDate,
			filterData.endDate,
			filterTypeQuerySelect,
			false,
			typeIncidences
		);

		setDynamicColumns(columns);
		setUpdateTable(!updateTable);
	}, [showColumnsDate]);

	useEffect(() => {
		if (stateGetAttendanceDetail.data !== null) {
			setExpandableRowTableQuery(stateGetAttendanceDetail.data);
		}
	}, [stateGetAttendanceDetail.data]);

	function handleSetFilterData(e) {
		const { name, value, checked, type } = e.target;
		setFilterData({
			...filterData,
			[name]: type === 'checkbox' ? checked : value,
		});
	}

	async function getReloadData() {
		return await getDataQueries();
	}

	function handleStartAndEndDate(event) {
		const { name, value } = event.target;

		// Update the respective key based on the name of the field
		if (name === 'startDate') {
			setForceUpdateKey((prevKey) => prevKey + 1);
		} else if (name === 'endDate') {
			setForceUpdateKeyTow((prevKey) => prevKey + 1);
		}

		// Update the filter data
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value,
		}));
	}

	function clearFilters() {
		setFilterTypeQuerySelect({
			name: 'Incidencias',
			code: 'I',
		});
		setFilterData(initialValuesFilter);
	}

	// Generación de datos de consulta
	const generateFilteredData = (catalog, selected) =>
		catalog.length === selected.length ? [] : generateArrayToIds(selected);

	async function getDataQueries() {
		const newEndDate = moment(filterData.endDate);
		const newStartDate = moment(filterData.startDate);
		const daysDiff = newEndDate.diff(newStartDate, 'days');
		const maxDate = newStartDate.clone().add(30, 'days');

		// Validaciones de rango de fechas
		if (newEndDate.isBefore(newStartDate)) {
			Toast(
				'warning',
				'La fecha de finalización no puede ser menor que la fecha de inicio.'
			);
			setFilterData((prev) => ({
				...prev,
				endDate: maxDate.toDate(),
			}));
			return;
		}

		if (daysDiff > 30) {
			Toast(
				'warning',
				'La diferencia entre las fechas no puede ser mayor de 30 días.'
			);
			setFilterData((prev) => ({
				...prev,
				endDate: maxDate.toDate(),
			}));
			return;
		}

		const dataToSend = {
			startDate: resetSeconds(filterData.startDate),
			endDate: resetSeconds(filterData.endDate),
			typePeriods: generateFilteredData(
				catalogData.periodType,
				filterData.typePeriods
			),
			workCenters: generateFilteredData(
				catalogData.workCenter,
				filterData.workCenters
			),
			workShifts: generateFilteredData(catalogData.shift, filterData.shifts),
			departments: generateFilteredData(
				catalogData.department,
				filterData.departments
			),
			jobsPosition: generateFilteredData(
				catalogData.position,
				filterData.positions
			),
			collaborators: generateFilteredData(
				catalogData.employee,
				filterData.collaborators
			),
		};

		// Configuración adicional según el tipo de consulta
		let queryPayload;
		if (filterData.typeQuery.code === 'I') {
			const typeIncidents = generateFilteredData(
				catalogData.incidentType,
				filterData.typeIncidents
			);
			queryPayload = { ...dataToSend, typeIncidents };
		} else {
			queryPayload = dataToSend;
		}

		setFilterTypeQuerySelect(filterData.typeQuery);
		if (filterData.typeQuery.code === 'R')
			setNameQuery(filterData.typeQuery.name);

		return filterData.typeQuery.code === 'R'
			? await getReportQuery({
					...dataToSend,
					inactivesToo: filterData.inactivesToo,
			  })
			: await getQueries(queryPayload, filterData.typeQuery, false);
	}
	const resetSeconds = (dateString) => {
		return moment(dateString).seconds(0).milliseconds(0).toISOString(); // Ajusta segundos y milisegundos a cero
	};

	function getQueryDataToSendReport() {
		const dataToSend = {
			startDate: resetSeconds(filterData.startDate),
			endDate: resetSeconds(filterData.endDate),
			typePeriods: generateFilteredData(
				catalogData.periodType,
				filterData.typePeriods
			),
			workCenters: generateFilteredData(
				catalogData.workCenter,
				filterData.workCenters
			),
			workShifts: generateFilteredData(catalogData.shift, filterData.shifts),
			departments: generateFilteredData(
				catalogData.department,
				filterData.departments
			),
			jobsPosition: generateFilteredData(
				catalogData.position,
				filterData.positions
			),
			collaborators: generateFilteredData(
				catalogData.employee,
				filterData.collaborators
			),
		};

		return {
			...dataToSend,
			inactivesToo: filterData.inactivesToo,
		};
	}

	useEffect(() => {
		if (getQueriesState.queries !== null) {
			const { tableData, columns, nameScreen } = getQueriesState.queries;

			setDynamicColumns(columns);
			setTableDataQuery(tableData); // se debe cambiar este campo cuando ya obtengamos datos de la tabla
			setNameQuery(nameScreen);
			//setShowColumnsDate(false);
		} else {
			setDynamicColumns({ open: [], close: [] });
			setTableDataQuery(null); // se debe cambiar este campo cuando ya obtengamos datos de la tabla
			setNameQuery('');
			//	setShowColumnsDate(false);
		}
	}, [getQueriesState]);

	async function toExcelExport() {
		if (tableDataQuery !== null && tableDataQuery?.length !== 0) {
			const copyTableDataQuery = JSON.parse(JSON.stringify(tableDataQuery));
			const addedDates = addMissingDates(
				copyTableDataQuery,
				filterData.startDate,
				filterData.endDate
			);
			const rangeDate = {
				start: filterData.startDate,
				end: filterData.endDate,
			};
			const body =
				filterData.typeQuery.code === 'I'
					? dataToExportIncidences(
							showColumnsDate ? dynamicColumns?.open : dynamicColumns?.close,
							companyState,
							addedDates,
							catalogData.incidentType,
							rangeDate,
							sort[0]
					  )
					: dataToExportChecks(
							showColumnsDate ? dynamicColumns?.open : dynamicColumns?.close,
							companyState,
							addedDates,
							rangeDate,
							sort[0]
					  );

			dispatchLoading({ type: LOADING_ON });
			await downloadFileBase64(
				body,
				'xlsx',
				filterData.typeQuery.code === 'I'
					? `Incidencias_${getDateTime()}`
					: `Checadas_${getDateTime()}`
			)
				.then(() => {
					dispatchLoading({ type: LOADING_OFF });
				})
				.catch((error) => {
					dispatchLoading({ type: LOADING_OFF });
				});
		} else {
			return Toast('warning', 'No hay datos para exportar');
		}
	} //funcion para exportacion

	async function toExcelExportAssistanceRegister() {
		if (expandableRowTableQuery !== null) {
			const body = getQueryDataToSendReport();
			return exportAttendanceRegister(globalFilterValue, body);
		} else {
			return Toast('warning', 'No hay datos para exportar');
		}
	}

	const iconsData = [
		{
			icon: 'excel',
			title: GeneralNames.GeneralExportToExcel,
			onClick:
				filterData.typeQuery.code !== 'R'
					? toExcelExport
					: toExcelExportAssistanceRegister,
		},
	];

	function clearTextLabel() {
		setGlobalFilterValue('');
		setFilters({
			global: { value: '', matchMode: FilterMatchMode.CONTAINS },
		});
	}

	function onGlobalFilterChange(e) {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	}

	return (
		<QueriesServiceManager.Provider
			value={{
				typeQuery,
				clearFilters,
				forceUpdateKey,
				forceUpdateKeyTwo,
				filterTypeQuerySelect,
				setFilterTypeQuerySelect,
				showColumnsDate,
				catalogData,
				filterData,
				handleSetFilterData,
				handleStartAndEndDate,
				getDataQueries,
				dynamicColumns,
				updateTable,
				setUpdateTable,
				setShowColumnsDate,
				setDynamicColumns,
				setTableDataQuery,
				nameQuery,
				clearTextLabel,
				onGlobalFilterChange,
				globalFilterValue,
				tableDataQuery,
				iconsData,
				filters,
				sort,
				setSort,
				loadingState,
				expandableRowTableQuery,
				sortAttendance,
				setSortAttendance,
			}}>
			{children}
		</QueriesServiceManager.Provider>
	);
};
