import React, { useEffect } from 'react';

const GoogleMapsLoader = ({ apiKey, libraries = 'places' }) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`;
		script.async = true;
		script.defer = true;

		document.body.appendChild(script);

		return () => {
			// Elimina el script al desmontar el componente
			document.body.removeChild(script);
		};
	}, [apiKey, libraries]);

	return null; // Este componente no renderiza nada en el DOM
};

export default GoogleMapsLoader;
