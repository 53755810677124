import { DataTable } from 'primereact/datatable';
import React, { useState } from 'react';

export const ExExpandableRowGroupsTable = ({
	children,
	value = [],
	style,
	header,
	footerTemplate,
	headerTemplate,

	doubleClick,
	tableClassName,
	height = 'h-9',
	rows = undefined,
	id = undefined,
	...props
}) => {
	const [showMenu, setShowMenu] = useState(false);
	const h = showMenu ? ' h-68 md:h-60 ' : ' h-5 ';

	return (
		<div className='flex w-full h-full flex-col'>
			{header !== undefined ? (
				<>
					<div
						className={
							'hidden lg:flex xl:flex 2xl:flex w-full  py-1 pl-1  ' + height
						}>
						{header}
					</div>
					<div
						className={
							'flex lg:hidden xl:hidden 2xl:hidden w-full h-full  py-1 pl-1 border' +
							h
						}>
						{showMenu === false ? (
							<div
								onClick={() => setShowMenu(!showMenu)}
								className='flex  w-full  justify-end content-start items-center '>
								<p className='!text-primary-ShadowPCP004 mr-2 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
									<span className={'icon-menu'}></span>
								</p>
							</div>
						) : (
							<div className='flex lg:hidden xl:hidden 2xl:hidden w-full h-full py-1 pl-1 border flex-row'>
								<div className='flex h-full flex-grow'>{header}</div>
								<div
									onClick={() => setShowMenu(!showMenu)}
									className='flex   justify-end content-start items-center'>
									<p className='!text-primary-ShadowPCP004 mr-2 flex !text-2xl justify-center content-center items-center hover:!text-secondary-PCS002 '>
										<span className={'icon-menu'}></span>
									</p>
								</div>
							</div>
						)}
					</div>
				</>
			) : null}
			<div className='flex w-full h-full pl-1 pr-1'>
				<div className='flex w-full h-full relative  border shadow-2xl'>
					<DataTable
						value={value}
						rowGroupMode='subheader'
						responsiveLayout='scroll'
						columnResizeMode='fit'
						scrollable
						scrollHeight='flex'
						scrollDirection='both'
						rowGroupHeaderTemplate={headerTemplate}
						rowGroupFooterTemplate={footerTemplate}
						currentPageReportTemplate='Total de registros {totalRecords}'
						tableClassName={'!absolute !top-0 !left-0 !right-0 !bottom-0 '}
						className='flex w-full h-full relative !font-karla !text-[14px]'
						rowClassName='hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004 '
						{...props}>
						{children}
					</DataTable>
				</div>
			</div>
		</div>
	);
};
