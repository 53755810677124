import React, { useContext } from 'react';
import personiaLogo from '../../assets/img/Logo_CONTPAQi_Colabora.png';
import { CompanyInfo } from '../CompanyInfo';
import { ProfileCard } from '../ProfileCard';
import { ReloadContext } from '../../context/ReloadProvider';

import { HeaderWeb } from './HeaderWeb';
import { msalConfig } from './../../Auth/b2cService/b2cServiceConfig';

export const NavBar = ({
	showPopover,
	setShowPopover,
	refCloseProfileCard,
	refCloseProfileCardWeb,
	closeFunction,
	setShowDialog,
}) => {
	const onHelpAction = () => {
		const buttton = document.getElementsByClassName('inmplayer-trigger');
		return buttton[0].click();
	};

	const onChangeCompany = () => {
		// get from local storage or from user profile if not exists
		const redirect = localStorage.getItem('ctiPortalURI');
		//delete all " from the url

		if (redirect !== null) {
			const url = redirect.replace(/\"/g, '');
			// open the url in a new tab
			window.open(url, '_blank');
		} else {
			const urlCti = msalConfig.ctiPortalURI;
			window.open(urlCti, '_blank');
		}
	};

	const { setReload } = useContext(ReloadContext);

	const onRefreshAction = () => setReload(true);
	const items = [
		{
			id: 1,
			title: 'Ayuda',
			action: onHelpAction,
			icon: 'icon-help',
		},
		{
			id: 2,
			title: 'Empresa',
			action: onChangeCompany,
			icon: 'icon-company',
		},
		{
			id: 3,
			title: 'Refrescar',
			action: onRefreshAction,
			icon: 'icon-refresh',
		},
	];
	const renderIconsItemsNavBar = (item, key, action, icon) => {
		return (
			<div title={item.title} className='flex h-full w-1/3' key={key}>
				<div
					key={key}
					onClick={action}
					className={`tooltip-${key} flex-grow flex text-[#5e5e5e]  items-center content-center justify-center cursor-pointer hover:text-[#0a6ebd] hover:bg-[#5e5e5e1e]`}>
					<p className='!text-secondary-PCS002 flex !text-2xl justify-center content-center items-center '>
						<span className={item.icon}></span>
					</p>
				</div>
			</div>
		);
	};

	const renderImgNav = () => {
		return (
			<div className='flex flex-row h-full w-full justify-center item-center  content-center  '>
				<div className='flex w-full h-full  justify-start item-center  content-center   '>
					<img className=' max-w-full h-auto  ' src={personiaLogo} alt='Logo' />
				</div>
			</div>
		);
	};

	const renderCall = () => {
		const isPayrollCall = sessionStorage.getItem('isPayrollCall');
		return (
			<div className='flex h-full w-full border-border border-x justify-center'>
				<div className='flex h-full w-2/3'>
					{' '}
					{/* Ajusté el ancho del contenedor del icono */}
					<div
						className={`flex-grow flex text-[#5e5e5e] items-center justify-center  `}>
						<p className='!text-secondary-PCS002 text-xl'>
							{isPayrollCall === 'false' ? (
								<span
									className='pi pi-users'
									style={{ fontSize: '1.5rem' }}></span>
							) : (
								<span
									className='pi pi-desktop'
									style={{ fontSize: '1.5rem' }}></span>
							)}
						</p>
					</div>
				</div>
			</div>
		);
	};

	const renderProfileCard = () => {
		return (
			<ProfileCard
				showPopover={showPopover}
				setShowPopover={setShowPopover}
				refCloseProfileCard={refCloseProfileCard}
				closeFunction={closeFunction}
				setShowDialog={setShowDialog}
			/>
		);
	};

	const renderOptionsIcons = () => {
		return (
			<div className='flex h-full w-full border-border border-x'>
				{items.map((item, key) => {
					return renderIconsItemsNavBar(item, key, item.action, item.icon);
				})}
			</div>
		);
	};

	return (
		<HeaderWeb
			content={
				<div className='flex h-full w-full flex-row justify-between '>
					<div className='flex w-[25%] md:w-[15%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%] '>
						{renderImgNav()}
					</div>
					<div className='flex items-center justify-center w-[60px] pr-2'>
						{renderCall()}
					</div>
					<div className='flex w-[30%] md:w-[55%] lg:w-[50%] xl:w-[60%] 2xl:w-[70%] truncate  '>
						<CompanyInfo />
					</div>
					<div className='flex w-[45%] md:w-[40%] lg:w-[40%] xl:w-[50%]  2xl:w-[20%]  '>
						{renderOptionsIcons()}
						{renderProfileCard()}
					</div>
				</div>
			}
		/>
	);
};
