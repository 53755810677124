import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import {
	dayNames,
	dayNamesMin,
	dayNamesShort,
	monthNames,
	monthNamesShort,
} from '../../utils/utils';

const CustomDatePicker = ({
	value,
	setValue,
	name,
	label,
	size,
	filterDate,
	minDateField,
	className,
	onChange,
	labelClassName,
	important,
	minDate = null,
	error = false,
	errorText = '',
	maxDateField = null,
	showIcon = true,
	...props
}) => {
	let today = new Date();
	addLocale('es', {
		firstDayOfWeek: 1,
		dayNames: dayNames,
		dayNamesShort: dayNamesShort,
		dayNamesMin: dayNamesMin,
		monthNames: monthNames,
		monthNamesShort: monthNamesShort,
		today: 'Hoy',
		clear: 'Limpiar',
	});

	return (
		<div className={'flex w-full h-full'}>
			<Calendar
				showButtonBar
				id='icon'
				className={
					(error && errorText ? 'p-invalid' : '') +
					' !border-none !outline-none !p-0 !text-base !font-input-font-family !w-full lg:!text-xs'
				}
				panelClassName={`!w-[408px]`}
				value={value}
				onChange={setValue}
				//minDate={minDate !== null ? minDate : null}
				todayButtonClassName='hidden'
				dateFormat='dd-MM-yy'
				showIcon={showIcon}
				placeholder={label}
				name={name}
				locale='es'
				{...props}
			/>

			{error && errorText && (
				<small htmlFor={name} className='p-error block !font-input-font-family'>
					{errorText}
				</small>
			)}
		</div>
	);
};

export default CustomDatePicker;
