import {
	GET_COLLABORATOR,
	GET_COLLABORATOR_ERROR,
	GET_COLLABORATOR_SUCCESS,
	ERROR_SHOW,
} from '../../constants';

export const getCollaboratorReducer = (state, actions) => {
	switch (actions.type) {
		case GET_COLLABORATOR:
			return {
				...state,
				collaborators: undefined,
				loading: true,
			};
		case GET_COLLABORATOR_ERROR:
			return {
				...state,
				collaborators: state.collaborators,
				//	collaboratorsOptions: state.collaboratorsOptions,
				loading: false,
				error: actions.payload,
				errorShow: false,
			};
		case GET_COLLABORATOR_SUCCESS:
			return {
				...state,
				collaborators: actions.payload,
				//collaboratorsOptions: getCollaboratorOptions(actions.payload),
				loading: false,
				error: null,
				errorShow: false,
			};
		case ERROR_SHOW:
			return {
				...state,
				errorShow: true,
			};
		default:
			return state;
	}
};
