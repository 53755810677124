import { InvitationsServiceManagerProvider } from '../../Services/Invitations/InvitationsService';
import { composeProviders } from './composerProvider';
import { EmployeeManagementFilterProvider } from './queries/EmployeeManagementFilterContext';
import { SendIncidencesFilterProvider } from './SendIncidences/SendIncidencesFilterContext';

const Providers = composeProviders(
	SendIncidencesFilterProvider,
	EmployeeManagementFilterProvider,
	InvitationsServiceManagerProvider
);

export default Providers;
