import React, { useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { WizardDialog } from '../../../../components/Wizard/WizardDialog';
import { CoordinateComponent } from './CoordinateComponent';
import { BaseButton } from '../../../../components/Buttons/BaseButton';
import { CollaboratorGridWorkCenter } from './CollaboratorGridWorkCenter';
import { ProgressSpinner } from 'primereact/progressspinner';
import { StoreContext } from '../../../../business/Provider';
import { WorkAreaCollaboratorNames } from '../../../../namesConstants/names';
import { ToolbarTwoColumns } from '../../../../components/Table/ToolbarTwoColumns';
import { initialStateTable } from '../../../Invitations/const';

export const WorkCenterDialog = ({
	open,
	onClose,
	title,
	activeStep,
	setActiveStep,
	formik,
	openModalCollaborators,
	address,
	setAddress,
	collaboratorsList,
	getCollaboratorsByWorkCenterByPaginator,
	listOfCollaborators,
	onSubmit,
	isAddressValid,
	setIsAddressValid,
	geolocation,
	setGeolocation,
	circles,
	setCircles,
	setIsEdit,
	coordinates,
	setCoordinates,
	isEdit,
	getCollaboratorsByWorkCenter,
}) => {
	const [filters, setFilters] = useState(initialStateTable);
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const stepsArray = [
		{
			label: 'Datos Generales',
			title: 'Datos Generales',
			validationFields: ['geoLocation', 'zipCode', 'name'],
		},
		{
			label: 'Colaboradores',
			title: 'Colaboradores',
		},
	];

	const { loadingState } = useContext(StoreContext);

	const handleNext = async (e) => {
		e.preventDefault();
		//Get All Errors
		const validationErrors = await formik.validateForm();

		//delete key from object if not exist in validationFields
		const errors = Object.keys(validationErrors).reduce((acc, key) => {
			if (stepsArray[activeStep].validationFields.includes(key)) {
				acc[key] = validationErrors[key];
			}
			return acc;
		}, {});

		if (Object.keys(errors).length > 0) {
			//Filter the error that belongs to the step.
			if (stepsArray[activeStep].validationFields) {
				//Execute Touch for validation fields.
				let validationFields = stepsArray[activeStep].validationFields;
				validationFields.forEach((element) =>
					formik.setFieldTouched(element, true)
				);
				//formik.setTouched(setNestedObjectValues(validationErrors, true));
			}
		} else if (address === '') {
			formik.setFieldTouched('street', true);
		} else if (formik.values.name === '') {
			formik.setFieldTouched('name', true);
		} else if (
			formik.values.geoFenceSize === '' ||
			formik.values.geoFenceSize === 0
		) {
			formik.setFieldTouched('geoFenceSize', true);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const renderMapForm = () => {
		return (
			<CoordinateComponent
				address={address}
				setAddress={setAddress}
				formik={formik}
				isAddressValid={isAddressValid}
				setIsAddressValid={setIsAddressValid}
				geolocation={geolocation}
				setGeolocation={setGeolocation}
				circles={circles}
				setCircles={setCircles}
				coordinates={coordinates}
				setCoordinates={setCoordinates}
			/>
		);
	};
	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	};

	const clearTextLabel = () => {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setGlobalFilterValue(newValue);
		setFilters(_filters);
	};
	const renderHeaderGrid = () => {
		return (
			<div className='w-full flex flex-row !h-[60px]'>
				<ToolbarTwoColumns
					hidden={formik.values.homeOffice}
					onChange={onGlobalFilterChange}
					placeholder={WorkAreaCollaboratorNames.FindCollaborator}
					value={globalFilterValue}
					clearTextLabel={clearTextLabel}
					type={isEdit ? 'Edit' : 'Add'}
					onClickAction={() => {
						if (!formik.values.homeOffice) {
							openModalCollaborators(true);
						}
						getCollaboratorsByWorkCenter();
					}}
				/>
			</div>
		);
	};
	const renderCollaboratorList = () => {
		return (
			<div className='w-full h-full flex-col flex'>
				{renderHeaderGrid()}
				<CollaboratorGridWorkCenter
					listOfCollaborators={listOfCollaborators}
					name={'collaboratorgrid'}
					text={'Por favor agregue colaboradores'}
					getCollaboratorsByWorkCenterByPaginator={
						getCollaboratorsByWorkCenterByPaginator
					}
					filters={filters}
				/>
			</div>
		);
	};

	const renderStep = (step) => {
		switch (step) {
			case 0:
				return renderMapForm();
			case 1:
				return renderCollaboratorList();
			default:
				return <></>;
		}
	};
	const renderLoading = () => {
		return loadingState ? (
			<div className='flex h-full w-full absolute  z-50  bg-black opacity-80 justify-center content-center items-center flex-col'>
				<ProgressSpinner mode='indeterminate' className='flex w-full h-8 ' />
			</div>
		) : null;
	};
	return (
		<Dialog
			header={
				<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
					{title}
				</p>
			}
			visible={open}
			modal
			style={{ width: '80vw', height: '85vh', position: 'relative' }}
			onHide={() => onClose()}>
			{
				<WizardDialog
					nextButtonText='Colaboradores'
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					steps={stepsArray}
					formik={formik}
					onNext={handleNext}
					onBack={handleBack}
					onClose={() => onClose()}
					handleBack={handleBack}
					handleNext={handleNext}
					onSubmit={onSubmit}
					open={open}
					homeOffice={formik.values.homeOffice}
					title={title}>
					{renderLoading()}
					<div className='border-b border-border p-1 flex-grow'>
						{renderStep(activeStep)}
					</div>
				</WizardDialog>
			}
		</Dialog>
	);
};
